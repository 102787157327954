import React, { useEffect, useState } from "react";

const Footer = () => {
  const [width, setWidth] = useState("calc(100% - 260px)");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 700) {
        setWidth("calc(100% - 260px)");
      } else if (window.innerWidth < 700) {
        setWidth("100%");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        width: width,
        color: "#242424",
        zIndex: "50",
        height: "50px",
        display: "flex",
        backgroundColor: "#F3F1EC",
        padding: "0",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: "0",
      }}
    >
      <span>Product design and develop by </span>
      <a
        href="https://bizzclan.com/"
        target="_blank"
        rel="noreferrer"
        className="pl-2"
      >
        {" "}
        BizzClan
      </a>
    </div>
  );
};

export default Footer;
