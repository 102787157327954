import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";

// axios.defaults.baseURL = "https://dd6c-59-103-221-2.ngrok-free.app/api/";

axios.defaults.baseURL = process.env.REACT_APP_APP_BASE_URL;

// axios.defaults.headers =
// {
//   'Authorization': `Bearer ${localStorage.getItem('x-auth-token')}`,
//   // 'x-auth-token': localStorage.getItem('x-auth-token'),
//   'Content-Type': 'application/json'
// }
export const updateAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  } else {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("x-auth-token")}`;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  }
};
ReactDOM.render(
  <React.StrictMode>
    <App />
    {updateAuthorizationToken()}
  </React.StrictMode>,
  document.getElementById("root")
);
