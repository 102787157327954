import React from "react";

const Header = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const Logout = () => {
    localStorage.clear();
  };

  return (
    <div
      className="w-100 d-flex justify-content-end"
      style={{ color: "black" }}
    >
      <div className="dropdown">
        <div
          className="btn dropdown-toggle d-flex"
          style={{
            backgroundColor: "#fe6f35",
            borderRadius: "16px",
            padding: "12px",
            height: "auto",
            alignItems: "center",
            gap: "8px",
          }}
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            src={user?.image}
            style={{
              width: "48px",
              height: "48px",
              objectFit: "cover",
              borderRadius: "100%",
              cursor: "pointer",
            }}
            alt=""
          />
          <span className="text-white">{user?.username}</span>
        </div>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href={`/user/edit/${user?._id}`}>
            Edit Profile
          </a>
          <a className="dropdown-item" href="/logout" onClick={Logout}>
            Logout
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
