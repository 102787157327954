import React, { useState, useCallback, useEffect } from "react";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import Header from "../Header";
import axios from "axios";
import { message } from "antd";
import { useHistory } from "react-router-dom";

const CreateCoupon = () => {
  const history = useHistory();
  const [couponList, setCouponList] = useState([]);

  const getCouponList = useCallback(() => {
    axios
      .get("coupon/get-coupons")
      .then((response) => {
        setCouponList(response.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    getCouponList();
  }, [getCouponList]);


  const [couponData, setCouponData] = useState({
    code: "",
    percentage: "",
    end_date: "",
    duration: "",
  });
  
  const SubmitForm = () => {
    if (
      couponData.code === "" ||
      couponData.percentage === "" ||
      couponData.end_date === "" ||
      couponData.duration === ""
    ) {
      message.error("Please fill all the fields");
      return;
    }
    if(couponList.filter(cop => cop.coupon_code === couponData.code).length > 0) {
      message.error("Cannot create duplicate coupon code");
      return false;
    }
    axios
      .post("coupon/create", {
        coupon_code: couponData.code,
        percentage: couponData.percentage,
        end_date: couponData.end_date,
        duration_in_months: couponData.duration,
      })
      .then((response) => {
        message.success("Coupon created successfully");
        history.push("/coupon");
      })
      .catch((error) => {
        message.error("Cannot create duplicate coupon code");
      });
  };

  return (
    <>
      <Sidebar />
      <div className="content pt-3">
        <Header />
        <div className="col-12 pt-3">
          <div className="displayFlex ">
            <div className="displayFlex">
              <h2 className="mr-auto">Create Coupon</h2>
            </div>
          </div>

          <div className="mt-3">
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: "500px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label htmlFor="code">Coupon Code:</label>
                <input
                  type="text"
                  name="code"
                  id="code"
                  placeholder="Enter Coupon Code"
                  className="form-control"
                  value={couponData.code}
                  onChange={(e) =>
                    setCouponData({
                      ...couponData,
                      code: e.target.value,
                    })
                  }
                />
              </div>
              <div
                style={{
                  width: "100%",
                  maxWidth: "500px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label htmlFor="percentage">Percentage:</label>
                <input
                  type="text"
                  name="percentage"
                  id="percentage"
                  placeholder="Enter Percentage"
                  className="form-control"
                  value={couponData.percentage}
                  onChange={(e) =>
                    setCouponData({
                      ...couponData,
                      percentage: e.target.value,
                    })
                  }
                />
              </div>
              <div
                style={{
                  width: "100%",
                  maxWidth: "500px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label htmlFor="duration">Duration in Months:</label>
                <input
                  type="text"
                  name="duration"
                  id="duration"
                  placeholder="Enter duration in months"
                  className="form-control"
                  value={couponData.duration}
                  onChange={(e) =>
                    setCouponData({
                      ...couponData,
                      duration: e.target.value,
                    })
                  }
                />
              </div>
              <div
                style={{
                  width: "100%",
                  maxWidth: "500px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label htmlFor="end_date">End Date:</label>
                <input
                  type="date"
                  name="end_date"
                  id="end_date"
                  min={new Date().toISOString().split("T")[0]}
                  placeholder="Enter date"
                  className="form-control"
                  value={couponData.end_date}
                  onChange={(e) =>
                    setCouponData({
                      ...couponData,
                      end_date: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <button
              className="btn Radius8 White py-2 mt-2"
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
              onClick={SubmitForm}
            >
              Create
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateCoupon;
