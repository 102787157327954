/* eslint-disable no-unused-vars */
import Sidebar from "../Sidebar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import Footer from "../Footer";
import Header from "../Header";

const Notifications = () => {
  const initialstate = {
    subject: "",
    subjectError: "",
    description: "",
    descriptionError: "",
    apiKey: "",
    users: "",
    user: [],
  };

  const [DetailData, setDetailData] = useState(initialstate);
  // eslint-disable-next-line
  const {
    subject,
    description,
    apiKey,
    users,
    user,
    subjectError,
    descriptionError,
  } = DetailData;

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setDetailData({
      ...DetailData,
      [name]: value,
    });
  };
  function validate() {
    let subjectError = "";
    let descriptionError = "";
    if (!description) {
      descriptionError = "Please provide Description";
    }
    if (!subject) {
      subjectError = "Subject is required";
    }
    if (subjectError || descriptionError) {
      setDetailData({
        ...DetailData,
        subjectError,
        descriptionError,
      });
      return false;
    }
    return true;
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async (value, keyword) => {
    try {
      const res = await Promise.all([axios.get(`users`)]);
      setDetailData({
        ...DetailData,
        user: res[0].data.users,
      });
    } catch {
      // setDetailData({
      //     ...DetailData,
      //     errorMessage: "Something went wrong"
      // })
      message.error("Server Error");
    }
  };

  const SubmitForm = () => {
    if (validate()) {
      const link = "notification";

      axios
        .post(link, {
          subject: subject,
          description: description,
          userType: users,
        })
        .then((res) => {
          if (res.data.success === true) {
            message.success(res.data.message);
            window.location = "/notifications";
          }
        })
        .catch(function (error) {
          setDetailData({
            ...DetailData,
            loading: false,
          });
        });
    }
  };
  return (
    <>
      <Sidebar />
      <div className="content pt-3 pl2">
        <Header />
        <h2 className="pt-3">Send Push Notifications </h2>
        <div className=" mt-4">
          <h4 className="">Notifications</h4>
          <div className="col-md-6 col-12 px-0">
            <div className="">
              <input
                placeholder="Subject"
                name="subject"
                value={subject}
                onChange={onHandleChange}
                className="col-12 fillColor py-2"
              />

              <div className="mt-2" />
              <textarea
                rows={3}
                style={{ resize: "none" }}
                placeholder="Description"
                name="description"
                value={description}
                onChange={onHandleChange}
                className="col-12 fillColor py-2"
              />

              <div className=" ">
                <select
                  onChange={onHandleChange}
                  name="users"
                  className="fillColor col-12 py-2"
                >
                  <option value="" className="blue">
                    Select User
                  </option>
                  {user &&
                    user.map((value) => {
                      return (
                        <option value={value._id} className="blue">
                          {value.firstName + " " + value.lastName}
                        </option>
                      );
                    })}
                </select>
              </div>
              <button
                className="btn Radius8 White py-2 mt-2"
                onClick={SubmitForm}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Notifications;
