import Sidebar from "../Sidebar";
import { Form, Button, message, Space } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as EyeIcon } from "../../assests/eye.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../Footer";
import Header from "../Header";

const EditPage = () => {
  // eslint-disable-next-line no-unused-vars
  const [form] = Form.useForm();
  const { _id } = useParams();

  const onFinish = () => {
    message.success("Submit success!");
  };

  const initialstate = {
    metaTitle: "",
    metaUrl: "",
    metaDescription: "",
    count: "",
  };

  const [DetailData, setDetailData] = useState(initialstate);
  const { metaTitle, metaDescription, metaUrl, count } = DetailData;

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setDetailData({
      ...DetailData,
      [name]: value,
    });
  };

  const SubmitPage = () => {
    const link = "page/" + _id;
    axios
      .put(link, {
        title: metaTitle,
        url: metaUrl,
        description: metaDescription,
      })
      .then((res) => {
        if (res.data.success) {
          message.success("Page Updated Successfully");
          window.location = "/page/edit/" + _id;
        }
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async (value, keyword) => {
    try {
      const res = await Promise.all([axios.get("page/" + _id)]);
      setDetailData({
        ...DetailData,
        metaTitle: res[0].data.pageById.title,
        metaUrl: res[0].data.pageById.url,
        metaDescription: res[0].data.pageById.description,
        count: res[0].data.pageById.viewCount,
        load: true,
      });
    } catch {
      // setDetailData({
      //     ...DetailData,
      //     errorMessage: "Something went wrong"
      // })
      message.error("Server Error");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content pt-3 pl2">
        <Header />
        <h2 className="pt-3">Edit Page</h2>
        <div className="displayFlex mt-4"></div>
        <div className="mt-2">
          <div className="">
            <form onSubmit={onFinish}>
              <div className="d-inline-flex">
                <h6 className="font-weight-bold">{metaTitle} </h6>
                <h6 className="mx-2">
                  {" "}
                  <EyeIcon /> {count} Views
                </h6>
              </div>
              <div className="col-md-6 col-12 mt-2 px-0">
                <input
                  placeholder="Meta Title"
                  required
                  name="metaTitle"
                  value={metaTitle}
                  onChange={onHandleChange}
                  className="col-12 fillColor py-2"
                />
              </div>
              <div className="col-md-6 col-12 mt-2 px-0">
                <input
                  placeholder="Page Url"
                  required
                  name="metaUrl"
                  value={metaUrl}
                  onChange={onHandleChange}
                  className="col-12 fillColor py-2"
                />
              </div>

              <div className="col-md-6 col-12 my-2 px-0">
                <input
                  placeholder="Meta Description ()"
                  required
                  name="metaDescription"
                  value={metaDescription}
                  onChange={onHandleChange}
                  className="col-12 fillColor py-2"
                />
              </div>

              <div className="col-md-6 col-12 px-0">
                <Space size="middle" className="float-left">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="button"
                    onClick={SubmitPage}
                    className="float-right Radius8"
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default EditPage;
