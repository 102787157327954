import React from "react";
import "antd/dist/antd.css";
import { Spin, notification } from "antd";
import { Table } from "ant-table-extensions";
import Sidebar from "../Sidebar";
import dateFormat from "dateformat";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import Footer from "../Footer";
import Header from "../Header";
var customDate;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const columns = [
  {
    title: "Name",
    key: "name",
    sorter: (a, b) => a?.firstName.localeCompare(b?.firstName),
    render: (record) => <>{record?.firstName + " " + record?.lastName}</>,
  },
  {
    title: "Email",
    key: "email",
    sorter: (a, b) => a?.email.localeCompare(b?.email),
    render: (record) => <>{record?.email}</>,
  },
  {
    title: "Date",
    key: "createdAt",
    sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
    render: (record) => (
      <>{dateFormat(record.updatedAt, "yyyy/mm/dd, h:MM:ss tt")}</>
    ),
  },
  {
    title: "Plan",
    Key: "plan",
    sorter: (a, b) => a?.packageName.localeCompare(b?.packageName),
    render: (record) => <>{record?.packageName}</>,
  },
  {
    title: "Amount",
    Key: "amount",
    sorter: (a, b) => a?.updatedAt.localeCompare(b?.updatedAt),

    render: (record) => <>${record?.amount}</>,
  },

  {
    title: "Source",
    Key: "source",
    render: (record) => <>{record.paymentMethod}</>,
  },
  {
    title: "Payment Status",
    Key: "paymentStatus",
    render: (record) => {
      if (record?.paymentStatus) {
        return <span style={{ color: "green" }}>Success</span>;
      } else {
        return <span style={{ color: "red" }}>Failed</span>;
      }
    },
  },
];

// const expandable = { expandedRowRender: record => <p>{record.description}</p> };
const showHeader = true;
const pagination = { position: "bottom" };

var array;

class Transactions extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: "small",
    // expandable,
    title: undefined,
    showHeader,
    rowSelection: {},
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    search: "",
    bulkActions: "",
    filter: "",
    data: [],
    load: false,
    isModalVisible: false,
    errorMessage: null,
    selectionType: "checkbox",
    today: moment().format("YYYY-MM-DD"),
    day1: moment().subtract(1, "days").format("YYYY-MM-DD"),
    day7: moment().subtract(7, "days").format("YYYY-MM-DD"),
    day30: moment().subtract(30, "days").format("YYYY-MM-DD"),
  };
  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    // debugger;
    this.setState({ isModalVisible: false });
    const link = "contacts/byPeriod";
    axios
      .post(link, {
        startDate: customDate[0],
        endDate: customDate[1],
      })
      .then((res) => {
        if (res.data.success === true) {
          this.setState({
            data: res.data.Data,
          });
        }
      });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  onHandleChange = (event) => {
    const { name, value } = event.target;
    if (name === "filter" && value === "modal") {
      this.setState({ load: true, isModalVisible: true });
    }
    // eslint-disable-next-line
    else if (name === "filter" && value == 1) {
      this.setState({ load: true });
      const link = "contacts/byPeriod";
      axios
        .post(link, {
          startDate: this.state.day1,
          endDate: this.state.today,
        })
        .then((res) => {
          if (res.data.success === true) {
            this.setState({
              data: res.data.Data,
            });
          }
        });
    }
    // eslint-disable-next-line
    else if (name === "filter" && value == 7) {
      this.setState({ load: true });
      const link = "contacts/byPeriod";
      axios
        .post(link, {
          startDate: this.state.day7,
          endDate: this.state.today,
        })
        .then((res) => {
          if (res.data.success === true) {
            this.setState({
              data: res.data.Data,
            });
          }
        });
    }
    // eslint-disable-next-line
    else if (name === "filter" && value == 30) {
      this.setState({ load: true });
      const link = "contacts/byPeriod";
      axios
        .post(link, {
          startDate: this.state.day30,
          endDate: this.state.today,
        })
        .then((res) => {
          if (res.data.success === true) {
            this.setState({
              data: res.data.Data,
            });
          }
        });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  fetchData = async (value, keyword) => {
    try {
      const res = await Promise.all([axios.get("payments")]);
      this.setState({
        data: res[0].data.data,
        load: true,
      });
    } catch {
      this.setState({
        errorMessage: "Server Error",
      });
    }
  };

  DeleteMultiple = () => {
    const link = "contacts/deleteBulk";
    axios
      .post(link, {
        deleteContacts: array,
      })
      .then((res) => {
        this.fetchData();
        // if (res.data.success === true) {
        //     message.success('Contact Deleted Successfully')
        //     window.location = "/contact"
        // }
      })
      .catch(function (error) {});
  };

  componentDidMount() {
    this.fetchData();
  }
  showModal = () => {
    this.setState({ isModalVisible: true });
  };
  // handleOk = () => {
  //     this.setState({ isModalVisible: false })
  // };
  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleRowSelectionChange = (enable) => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleYScrollChange = (enable) => {
    this.setState({ yScroll: enable });
  };

  handleDataChange = (hasData) => {
    this.setState({ hasData });
  };

  render() {
    const { xScroll, yScroll, ...state } = this.state;

    const scroll = {};
    if (yScroll) {
      scroll.y = 240;
    }
    if (xScroll) {
      scroll.x = "100vw";
    }

    const tableColumns = columns.map((item) => ({
      ...item,
      ellipsis: state.ellipsis,
    }));
    if (xScroll === "fixed") {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = "right";
    }

    return (
      <>
        <Sidebar />
        <div className="content pt-3">
          <Header />
          <div className="col-12 pt-3">
            <div className="displayFlex ">
              <div className="displayFlex">
                <h2 className="mr-auto">Transactions</h2>
              </div>
              {/* <div className="displayFlex">
                <input
                  type="text"
                  className="lightBlue border-0 outline"
                  value={state.search}
                  onChange={this.onHandleChange}
                  name="search"
                  placeholder="Search"
                />
                <Button
                  className="bgBlue mx-1"
                  size={"small"}
                  onClick={() => this.fetchData("search", state.search)}
                >
                  {" "}
                  Search{" "}
                </Button>
              </div> */}
            </div>
            <div className="row">
              <button className="btn">All ({this.state?.data?.length}) </button>
              {/* <button className='btn'>| </button>
                            <button className='btn' >Spam </button> */}
            </div>
            <div className="displayFlex mt-3">
              {/* <div className="displayFlex">
                <select
                  value={state.bulkActions}
                  onChange={this.onHandleChange}
                  name="bulkActions"
                  className="blue Radius2"
                >
                  <option value="" className="blue">
                    Bulk Actions
                  </option>
                  <option value="saab" className="blue">
                    Delete
                  </option>
                </select>
                <Button
                  className="bgBlue mx-2"
                  size={"small"}
                  onClick={this.DeleteMultiple}
                >
                  {" "}
                  Apply{" "}
                </Button>
              </div> */}
              {/* <div className="displayFlex">
                <Button className="border-0 mx-2" size={"small"}>
                  {" "}
                  Filter{" "}
                </Button>
                <select
                  onChange={this.onHandleChange}
                  name="filter"
                  className="blue Radius2"
                >
                  <option value="" className="blue">
                    Select Period
                  </option>
                  <option value="1" className="blue">
                    {" "}
                    1 Day
                  </option>
                  <option value="7" className="blue">
                    7 Days
                  </option>
                  <option value="30" className="blue">
                    30 Days
                  </option>
                  <option className="blue" value="modal">
                    Custom{" "}
                  </option>
                </select>
                <Modal
                  title="Custom Filter"
                  visible={this.state.isModalVisible}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                >
                  <RangePicker
                    // showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD"
                    onChange={onChange}
                    onOk={onOk}
                  />
                </Modal>
              </div> */}
            </div>
          </div>
          <div
            className="mt-3"
            style={{ marginLeft: "15px", marginRight: "15px" }}
          >
            <Table
              {...this.state}
              rowKey="_id"
              //   rowSelection={{
              //     type: state.selectionType,
              //     ...rowSelection,
              //   }}
              pagination={{ position: [this.state.top, this.state.bottom] }}
              columns={tableColumns}
              dataSource={state.hasData ? this.state.data : null}
              loading={{
                indicator: (
                  <div>
                    <Spin indicator={antIcon} />
                  </div>
                ),
                spinning: !this.state.load,
              }}
              scroll={scroll}
              className="table-responsive"
              exportable
            />
          </div>
        </div>
        <Footer />
        {this.state.errorMessage !== null &&
          notification["error"]({
            message: "Error",
            description: this.state.errorMessage,
            placement: "bottomLeft",
          })}
      </>
    );
  }
}
export default Transactions;
