import React from "react";
import "antd/dist/antd.css";
import { Spin, notification, message, Button } from "antd";
import { Table } from "ant-table-extensions";
import dateFormat from "dateformat";
import axios from "axios";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const columns = [
  {
    title: "Email",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
];

var array = [];
// const expandable = { expandedRowRender: record => <p>{record.description}</p> };
const showHeader = true;
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    array = [];

    if (selectedRowKeys?.length > 0) {
      selectedRows.forEach((item) => {
        array.push(item.email);
      });
    }
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

class Newsletter extends React.Component {
  state = {
    loading: false,
    size: "small",
    // expandable,
    title: undefined,
    showHeader,
    rowSelection: {},
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    // bottom: 'bottomRight',
    search: "",
    bulkActions: "",
    filter: "",
    load: false,
    errorMessage: null,
    selectedRowKeys: [], // Check here to configure the default column
    selectionType: "checkbox",
    today: moment().format("YYYY-MM-DD"),
  };

  fetchData = async (page) => {
    try {
      const res = await axios.get(`/newsletter?pageNumber=${page}`);
      this.setState({
        data: res.data.newsletters,
        load: true,
        totalPage: res.data.pages,
      });
    } catch (error) {
      if (error?.response?.data?.code === 401) {
        this.setState({ errorMessage: "Session Expired! Login Again" });
        localStorage.clear();
        window.location = "/login";
      } else if (error?.response?.data?.code !== 401) {
        // this.setState({ errorMessage: error.response.data.message })
        message.error(error?.response?.data?.message);
      }
    }
  };

  deleteMultiple = () => {
    this.setState({
      load: false,
    });
    if (this.state.bulkActions === "delete") {
      const link = "/newsletter";
      axios
        .delete(link, {
          data: {
            emails: array,
          },
        })
        .then((res) => {
          console.log("res :", res);
          message.success("Newsletter Deleted Successfully");
          window.location = "/newsletter";
        })
        .catch((error) => {
          console.log("error :", error);
        });
    }
    this.setState({
      load: true,
    });
  };

  onHandleChange = (event) => {
    this.setState({
      bulkActions: event.target.value,
    });
  };

  componentDidMount() {
    this.fetchData(1);
  }

  onPageChange = (page) => {
    this.setState({
      current: page,
    });
    this.fetchData(page);
  };

  render() {
    const { xScroll, yScroll, ...state } = this.state;
    const scroll = {};
    if (yScroll) {
      scroll.y = 240;
    }
    if (xScroll) {
      scroll.x = "100vw";
    }

    const tableColumns = columns.map((item) => ({
      ...item,
      ellipsis: state.ellipsis,
    }));
    if (xScroll === "fixed") {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = "right";
    }

    return (
      <>
        <Sidebar />
        <div className="content pt-3">
          <Header />
          <div className="col-12 pt-3">
            <div className="displayFlex align-items-center mb-3">
              <div
                className="displayFlex align-items-center"
                style={{ gap: "8px" }}
              >
                <h2 className="m-0">Newsletter</h2>
              </div>
            </div>
            <div>
              <select
                value={state.bulkActions}
                onChange={this.onHandleChange}
                name="bulkActions"
                className="blue Radius2"
              >
                <option value="" className="blue">
                  Bulk Actions
                </option>
                <option value="delete" className="blue">
                  Delete
                </option>
              </select>
              <Button
                className="bgBlue mx-2"
                size={"small"}
                onClick={this.deleteMultiple}
              >
                {" "}
                Apply{" "}
              </Button>
            </div>
          </div>

          <div
            className="mt-3"
            style={{ marginLeft: "15px", marginRight: "15px" }}
          >
            <Table
              {...this.state}
              pagination={{
                pageSize: 10,
                current: this.state.current,
                onChange: this.onPageChange,
                total: this.state.totalPage * 10,
                showSizeChanger: false,
              }}
              columns={tableColumns}
              dataSource={state.hasData ? this.state.data : null}
              rowKey="_id"
              rowSelection={{
                type: state.selectionType,
                ...rowSelection,
              }}
              loading={{
                indicator: (
                  <div>
                    <Spin indicator={antIcon} />
                  </div>
                ),
                spinning: !this.state.load,
              }}
              scroll={scroll}
              className="table-responsive"
              exportable
            />
          </div>
        </div>
        <Footer />
        {this.state.errorMessage !== null &&
          notification["error"]({
            message: "Error",
            description: this.state.errorMessage,
            placement: "bottomLeft",
          })}
      </>
    );
  }
}
export default Newsletter;
