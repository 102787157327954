import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { ReactComponent as NewUsersIcon } from "../../assests/usersLight.svg";
import { ReactComponent as CancelledIcon } from "../../assests/usersdark.svg";
import { ReactComponent as FreeUsersIcon } from "../../assests/usersyellow.svg";
import { ReactComponent as HoursIcon } from "../../assests/usersgreen.svg";
import { ReactComponent as DollarIcon } from "../../assests/dollar.svg";
import moment from "moment";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { DatePicker, Modal } from "antd";
import Footer from "../Footer";
import Header from "../Header";
import Graph from "./graph";
import IncomeGraph from "./income.graph";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const Dashboard = () => {
  const state = {
    filter: "",
  };
  var today = moment().format("YYYY-MM-DD");
  var firstDayOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  var firstDayOfYear = moment().startOf("year").format("YYYY-MM-DD");
  var firstDayOfLastMonth = moment()
    .subtract(1, "months")
    .startOf("month")
    .format("YYYY-MM-DD");
  var lastDayOfLastMonth = moment()
    .subtract(1, "months")
    .endOf("month")
    .format("YYYY-MM-DD");
  var firstDayOfLastYear = moment()
    .subtract(1, "years")
    .startOf("year")
    .format("YYYY-MM-DD");
  var lastDayOfLastYear = moment()
    .subtract(1, "years")
    .endOf("year")
    .format("YYYY-MM-DD");
  var yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  const [loading, setLoading] = useState(false);
  const [cancelledMembers, setCancelledMembers] = useState();
  const [newMembers, setNewMembers] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [income, setIncome] = useState("");
  const [members, setMembers] = useState();
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(today);
  const [FormData, setFormData] = useState(state);
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const [freeMemberX, setFreeMemberX] = useState();
  const [freeMemberY, setFreeMemberY] = useState();
  const [freeMemberCount, setFreeMemberCount] = useState();
  const [monthlyMemberX, setMonthlyMemberX] = useState();
  const [monthlyMemberY, setMonthlyMemberY] = useState();
  const [monthlyMemberCount, setMonthlyMemberCount] = useState();
  const [yearlyMemberX, setYearlyMemberX] = useState();
  const [yearlyMemberY, setYearlyMemberY] = useState();
  const [yearlyMemberCount, setYearlyMemberCount] = useState();

  const rangePickerHandle = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const dateRange = async () => {
    setIsModalVisible(false);
    setLoading(true);
    const link = "payments/income";
    axios
      .post(link, {
        from: startDate,
        to: endDate,
      })
      .then((res) => {
        if (res.data.success === true) {
          setIncome(res?.data?.data?.totalIncome);
          setX(res?.data?.data?.transactions?.map((item) => item["createdAt"]));
          setY(res?.data?.data?.transactions?.map((item) => item["amount"]));
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    await axios
      .post("users/count_detail_free_member", {
        from: startDate,
        to: endDate,
      })
      .then((res) => {
        setFreeMemberCount(res?.data?.data?.count);
        const sortDataFreeMemberData = res?.data?.data?.detail?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setFreeMemberX(sortDataFreeMemberData.map((item) => item["date"]));
        setFreeMemberY(sortDataFreeMemberData.map((item) => item["count"]));
      });
    await axios
      .post("users/count_detail_monthly_member", {
        from: startDate,
        to: endDate,
      })
      .then((res) => {
        setMonthlyMemberCount(res?.data?.data?.count);
        const sortDataMonthlyMemberData = res?.data?.data?.detail?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setMonthlyMemberX(
          sortDataMonthlyMemberData.map((item) => item["date"])
        );
        setMonthlyMemberY(
          sortDataMonthlyMemberData.map((item) => item["count"])
        );
      });
    await axios
      .post("users/count_detail_yearly_member", {
        from: startDate,
        to: endDate,
      })
      .then((res) => {
        setYearlyMemberCount(res?.data?.data?.count);
        const sortDataYearlyMemberData = res?.data?.data?.detail?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setYearlyMemberX(sortDataYearlyMemberData.map((item) => item["date"]));
        setYearlyMemberY(sortDataYearlyMemberData.map((item) => item["count"]));
      });
    setLoading(false);
  };

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    if (name === "filter" && value === "modal") {
      setIsModalVisible(true);
      return;
    } else if (name === "filter" && value === "1") {
      setLoading(true);
      const link = "payments/income";
      axios
        .post(link, {
          from: firstDayOfMonth,
          to: today,
        })
        .then((res) => {
          if (res.data.success === true) {
            setIncome(res?.data?.data?.totalIncome);
            setX(
              res?.data?.data?.transactions?.map((item) => item["createdAt"])
            );
            setY(res?.data?.data?.transactions?.map((item) => item["amount"]));
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      axios
        .post("users/count_detail_free_member", {
          from: firstDayOfMonth,
          to: today,
        })
        .then((res) => {
          setFreeMemberCount(res?.data?.data?.count);
          const sortDataFreeMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setFreeMemberX(sortDataFreeMemberData.map((item) => item["date"]));
          setFreeMemberY(sortDataFreeMemberData.map((item) => item["count"]));
        });
      axios
        .post("users/count_detail_monthly_member", {
          from: firstDayOfMonth,
          to: today,
        })
        .then((res) => {
          setMonthlyMemberCount(res?.data?.data?.count);
          const sortDataMonthlyMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setMonthlyMemberX(
            sortDataMonthlyMemberData.map((item) => item["date"])
          );
          setMonthlyMemberY(
            sortDataMonthlyMemberData.map((item) => item["count"])
          );
        });
      axios
        .post("users/count_detail_yearly_member", {
          from: firstDayOfMonth,
          to: today,
        })
        .then((res) => {
          setYearlyMemberCount(res?.data?.data?.count);
          const sortDataYearlyMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setYearlyMemberX(
            sortDataYearlyMemberData.map((item) => item["date"])
          );
          setYearlyMemberY(
            sortDataYearlyMemberData.map((item) => item["count"])
          );
        });
      setLoading(false);
    } else if (name === "filter" && value === "7") {
      setLoading(true);
      const link = "payments/income";
      axios
        .post(link, {
          from: firstDayOfYear,
          to: today,
        })
        .then((res) => {
          if (res.data.success === true) {
            setIncome(res?.data?.data?.totalIncome);
            setX(
              res?.data?.data?.transactions?.map((item) => item["createdAt"])
            );
            setY(res?.data?.data?.transactions?.map((item) => item["amount"]));
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      axios
        .post("users/count_detail_free_member", {
          from: firstDayOfYear,
          to: today,
        })
        .then((res) => {
          setFreeMemberCount(res?.data?.data?.count);
          const sortDataFreeMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setFreeMemberX(sortDataFreeMemberData.map((item) => item["date"]));
          setFreeMemberY(sortDataFreeMemberData.map((item) => item["count"]));
        });
      axios
        .post("users/count_detail_monthly_member", {
          from: firstDayOfYear,
          to: today,
        })
        .then((res) => {
          setMonthlyMemberCount(res?.data?.data?.count);
          const sortDataMonthlyMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setMonthlyMemberX(
            sortDataMonthlyMemberData.map((item) => item["date"])
          );
          setMonthlyMemberY(
            sortDataMonthlyMemberData.map((item) => item["count"])
          );
        });
      axios
        .post("users/count_detail_yearly_member", {
          from: firstDayOfYear,
          to: today,
        })
        .then((res) => {
          setYearlyMemberCount(res?.data?.data?.count);
          const sortDataYearlyMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setYearlyMemberX(
            sortDataYearlyMemberData.map((item) => item["date"])
          );
          setYearlyMemberY(
            sortDataYearlyMemberData.map((item) => item["count"])
          );
        });
      setLoading(false);
    } else if (name === "filter" && value === "30") {
      setLoading(true);
      const link = "payments/income";
      axios
        .post(link, {
          from: firstDayOfLastMonth,
          to: lastDayOfLastMonth,
        })
        .then((res) => {
          if (res.data.success === true) {
            setIncome(res?.data?.data?.totalIncome);
            setX(
              res?.data?.data?.transactions?.map((item) => item["createdAt"])
            );
            setY(res?.data?.data?.transactions?.map((item) => item["amount"]));
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });

      axios
        .post("users/count_detail_free_member", {
          from: firstDayOfLastMonth,
          to: lastDayOfLastMonth,
        })
        .then((res) => {
          setFreeMemberCount(res?.data?.data?.count);
          const sortDataFreeMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setFreeMemberX(sortDataFreeMemberData.map((item) => item["date"]));
          setFreeMemberY(sortDataFreeMemberData.map((item) => item["count"]));
        });
      axios
        .post("users/count_detail_monthly_member", {
          from: firstDayOfLastMonth,
          to: lastDayOfLastMonth,
        })
        .then((res) => {
          setMonthlyMemberCount(res?.data?.data?.count);
          const sortDataMonthlyMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setMonthlyMemberX(
            sortDataMonthlyMemberData.map((item) => item["date"])
          );
          setMonthlyMemberY(
            sortDataMonthlyMemberData.map((item) => item["count"])
          );
        });
      axios
        .post("users/count_detail_yearly_member", {
          from: firstDayOfLastMonth,
          to: lastDayOfLastMonth,
        })
        .then((res) => {
          setYearlyMemberCount(res?.data?.data?.count);
          const sortDataYearlyMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setYearlyMemberX(
            sortDataYearlyMemberData.map((item) => item["date"])
          );
          setYearlyMemberY(
            sortDataYearlyMemberData.map((item) => item["count"])
          );
        });
      setLoading(false);
    } else if (name === "filter" && value === "40") {
      setLoading(true);
      const link = "payments/income";
      axios
        .post(link, {
          from: firstDayOfLastYear,
          to: lastDayOfLastYear,
        })
        .then((res) => {
          if (res.data.success === true) {
            setIncome(res?.data?.data?.totalIncome);
            setX(
              res?.data?.data?.transactions?.map((item) => item["createdAt"])
            );
            setY(res?.data?.data?.transactions?.map((item) => item["amount"]));
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      axios
        .post("users/count_detail_free_member", {
          from: firstDayOfLastYear,
          to: lastDayOfLastYear,
        })
        .then((res) => {
          setFreeMemberCount(res?.data?.data?.count);
          const sortDataFreeMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setFreeMemberX(sortDataFreeMemberData.map((item) => item["date"]));
          setFreeMemberY(sortDataFreeMemberData.map((item) => item["count"]));
        });
      axios
        .post("users/count_detail_monthly_member", {
          from: firstDayOfLastYear,
          to: lastDayOfLastYear,
        })
        .then((res) => {
          setMonthlyMemberCount(res?.data?.data?.count);
          const sortDataMonthlyMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setMonthlyMemberX(
            sortDataMonthlyMemberData.map((item) => item["date"])
          );
          setMonthlyMemberY(
            sortDataMonthlyMemberData.map((item) => item["count"])
          );
        });
      axios
        .post("users/count_detail_yearly_member", {
          from: firstDayOfLastYear,
          to: lastDayOfLastYear,
        })
        .then((res) => {
          setYearlyMemberCount(res?.data?.data?.count);
          const sortDataYearlyMemberData = res?.data?.data?.detail?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setYearlyMemberX(
            sortDataYearlyMemberData.map((item) => item["date"])
          );
          setYearlyMemberY(
            sortDataYearlyMemberData.map((item) => item["count"])
          );
        });
      setLoading(false);
    } else {
      setFormData({
        ...FormData,
        [name]: value,
      });
      fetchAllData();
    }
  };

  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line
  }, []);

  const fetchAllData = () => {
    axios
      .post("users/count_detail_free_member", {
        from: firstDayOfLastYear,
        to: today,
      })
      .then((res) => {
        setFreeMemberCount(res?.data?.data?.count);
        const sortDataFreeMemberData = res?.data?.data?.detail?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setFreeMemberX(sortDataFreeMemberData.map((item) => item["date"]));
        setFreeMemberY(sortDataFreeMemberData.map((item) => item["count"]));
      });
    axios
      .post("users/count_detail_monthly_member", {
        from: firstDayOfLastYear,
        to: today,
      })
      .then((res) => {
        setMonthlyMemberCount(res?.data?.data?.count);
        const sortDataMonthlyMemberData = res?.data?.data?.detail?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setMonthlyMemberX(
          sortDataMonthlyMemberData.map((item) => item["date"])
        );
        setMonthlyMemberY(
          sortDataMonthlyMemberData.map((item) => item["count"])
        );
      });
    axios
      .post("users/count_detail_yearly_member", {
        from: firstDayOfLastYear,
        to: today,
      })
      .then((res) => {
        setYearlyMemberCount(res?.data?.data?.count);
        const sortDataYearlyMemberData = res?.data?.data?.detail?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setYearlyMemberX(sortDataYearlyMemberData.map((item) => item["date"]));
        setYearlyMemberY(sortDataYearlyMemberData.map((item) => item["count"]));
      });

    fetchData();
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Promise.all([
        axios.post(`payments/income`),
        axios.get("users/count"),
      ]);
      setIncome(res[0]?.data?.data?.totalIncome);
      setMembers(res[1]?.data?.Data);
      setX(res[0]?.data?.data?.transactions?.map((item) => item["createdAt"]));

      setY(res[0]?.data?.data?.transactions?.map((item) => item["amount"]));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.code === 401) {
        localStorage.clear();
        window.location = "/login";
      }
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content pt-3">
        <Header />
        <div className="pt-3">
          <div className="row mx-0">
            <div className="  mx-1 mt-2">
              <h6 className="ml-3 mt-3">Members Month to Date</h6>
              <div className="row mx-2 my-2">
                <div
                  className="card mx-2 mt-1"
                  style={{
                    width: "200px",
                    border: "1px solid #fe6f35",
                    borderRadius: "4px",
                  }}
                >
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      {" "}
                      <NewUsersIcon />{" "}
                    </h5>
                    <h6 className="card-subtitle mt-2 text-center">
                      {loading === true
                        ? antIcon
                        : members?.newMembersCount + " Members"}
                    </h6>
                  </div>
                  <h6 className="card-subtitle bgBlue text-center p-1 m-0 font_16">
                    New Members
                  </h6>
                </div>
                <div
                  className="card mx-2 mt-1"
                  style={{
                    width: "200px",
                    border: "1px solid #48A7DF",
                    borderRadius: "4px",
                  }}
                >
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      {" "}
                      <CancelledIcon />{" "}
                    </h5>
                    <h6 className="card-subtitle mb-2 text-center">
                      {loading === true
                        ? antIcon
                        : members?.deactivateMembers + " Members"}
                    </h6>
                  </div>
                  <h6
                    className="card-subtitle text-center p-1 m-0 font_16"
                    style={{ backgroundColor: "#48A7DF", color: "white" }}
                  >
                    Cancelled Members
                  </h6>
                </div>
              </div>
            </div>
            <div className="  mx-1 mt-2">
              <h6 className="ml-3 mt-3">Total Members</h6>
              <div className="row  mx-2 my-2">
                <div
                  className="card mx-2 mt-1"
                  style={{
                    width: "200px",
                    border: "1px solid #FFB100",
                    borderRadius: "4px",
                  }}
                >
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      {" "}
                      <FreeUsersIcon />{" "}
                    </h5>
                    <h6 className="card-subtitle mt-2 text-center">
                      {loading === true ? antIcon : members?.free + " Members"}
                    </h6>
                  </div>
                  <h6
                    className="card-subtitle text-center p-1 m-0 font_16"
                    style={{ backgroundColor: "#FFB100", color: "white" }}
                  >
                    Free
                  </h6>
                </div>
                <div
                  className="card mx-2 mt-1"
                  style={{
                    width: "200px",
                    border: "1px solid #fe6f35",
                    borderRadius: "4px",
                  }}
                >
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      {" "}
                      <NewUsersIcon />{" "}
                    </h5>
                    <h6 className="card-subtitle mt-2 text-center">
                      {loading === true
                        ? antIcon
                        : members?.monthly + " Members"}
                    </h6>
                  </div>
                  <h6 className="card-subtitle bgBlue text-center p-1 m-0 font_16">
                    Paid Monthly
                  </h6>
                </div>
                <div
                  className="card mx-2 mt-1"
                  style={{
                    width: "200px",
                    border: "1px solid #12BF7D",
                    borderRadius: "4px",
                  }}
                >
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      {" "}
                      <HoursIcon />{" "}
                    </h5>
                    <h6 className="card-subtitle mt-2 text-center">
                      {loading === true
                        ? antIcon
                        : members?.yearly + " Members"}
                    </h6>
                  </div>
                  <h6
                    className="card-subtitle text-center p-1 m-0 font_16"
                    style={{ backgroundColor: "#12BF7D", color: "white" }}
                  >
                    Paid Yearly
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className=" mx-0 mt-2">
              <div className="row mx-2 my-2">
                <div
                  className="card mx-2 mt-1"
                  style={{
                    width: "300px",
                    border: "1px solid #22BF7D",
                    borderRadius: "4px",
                  }}
                >
                  <div className="card-body">
                    <div className="">
                      <div className="ml-auto mx-auto d-flex justify-content-center">
                        <h5 className="card-title ">
                          {" "}
                          <DollarIcon />{" "}
                        </h5>
                      </div>
                      <div className="mr-auto mx-auto">
                        <h6 className="card-subtitle mt-2 text-center font-weight-bold">
                          {loading === true
                            ? antIcon
                            : "$" +
                              `${
                                income ? parseFloat(income)?.toFixed(2) : "0"
                              }`}
                        </h6>
                        <h6 className="card-subtitle mt-2 text-center">
                          Income Generated
                        </h6>
                        <div className="w-100 d-flex justify-content-center mt-3">
                          <select
                            onChange={onHandleChange}
                            name="filter"
                            className="blue Radius2"
                          >
                            <option value="" className="blue">
                              Select Period
                            </option>
                            <option value="1" className="blue">
                              {" "}
                              Month to Date
                            </option>
                            <option value="7" className="blue">
                              Year to Date
                            </option>
                            <option value="30" className="blue">
                              Last Month
                            </option>
                            <option value="40" className="blue">
                              Last Year
                            </option>
                            <option className="blue" value="modal">
                              Custom{" "}
                            </option>
                          </select>
                        </div>
                        <Modal
                          title="Custom Filter"
                          visible={isModalVisible}
                          onOk={dateRange}
                          onCancel={() => setIsModalVisible(false)}
                        >
                          <RangePicker
                            size={"middle"}
                            defaultValue={[
                              moment(startDate, dateFormat),
                              moment(endDate, dateFormat),
                            ]}
                            format={dateFormat}
                            onChange={rangePickerHandle}
                          />
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {x && y && (
          <IncomeGraph x={x} y={y} title="Income Generated" color="#008FFB" />
        )}
        {freeMemberX && freeMemberY && (
          <Graph
            x={freeMemberX}
            y={freeMemberY}
            title="Free Members"
            color="#ffb100"
            count={freeMemberCount}
          />
        )}
        {monthlyMemberX && monthlyMemberY && (
          <Graph
            x={monthlyMemberX}
            y={monthlyMemberY}
            title="Monthly Members"
            color="#fe6f35"
            count={monthlyMemberCount}
          />
        )}
        {yearlyMemberX && yearlyMemberY && (
          <Graph
            x={yearlyMemberX}
            y={yearlyMemberY}
            title="Yearly Members"
            color="#12bf7d"
            count={yearlyMemberCount}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
