import React from "react";
import "antd/dist/antd.css";
import { Spin, notification, message } from "antd";
import { Table } from "ant-table-extensions";
import dateFormat from "dateformat";
import axios from "axios";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const columns = [
  {
    title: "Username",
    sorter: false,
    render: (record) => (
      <>
        <span className="font-weight-bold">{record.username}</span>
      </>
    ),
  },
  {
    title: "Name",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (record) => (
      <>
        <span className="">{record.firstName + " " + record.lastName}</span>
      </>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: "Subscription Type",
    sorter: (a, b) =>
      a.packageID?.packageType?.localeCompare(b?.packageID?.packageType),
    render: (record) => (
      <>
        <span className="">{record?.package?.packageType}</span>
      </>
    ),
  },
  {
    title: "Subscription Name",
    sorter: (a, b) => a.packageID?.name?.localeCompare(b?.packageID?.name),
    render: (record) => (
      <>
        <span className="">{record?.package?.name}</span>
      </>
    ),
  },
  {
    title: "Role",
    dataIndex: "role",
    sorter: (a, b) => a.role.localeCompare(b.role),
  },
  {
    title: "Country",
    dataIndex: "country",
    sorter: (a, b) => a.country.localeCompare(b.country),
  },
  {
    title: "State",
    dataIndex: "state",
    sorter: (a, b) => a.state.localeCompare(b.state),
  },
  {
    title: "Date Added",
    sorter: true,
    render: (record) => (
      <>{dateFormat(record.createdAt, "yyyy/mm/dd, h:MM:ss tt")}</>
    ),
  }
];

var array;
// const expandable = { expandedRowRender: record => <p>{record.description}</p> };
const showHeader = true;
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // DeleteMultiple(selectedRowKeys)
    if (selectedRowKeys?.length > 0) {
      array = selectedRowKeys;
    }
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

class Users extends React.Component {
  state = {
    loading: false,
    size: "small",
    // expandable,
    title: undefined,
    showHeader,
    rowSelection: {},
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    // bottom: 'bottomRight',
    search: "",
    bulkActions: "",
    filter: "",
    load: false,
    errorMessage: null,
    selectedRowKeys: [], // Check here to configure the default column
    selectionType: "checkbox",
    today: moment().format("YYYY-MM-DD"),
    firstDayOfMonth: moment().startOf("month").format("YYYY-MM-DD"),
    firstDayOfYear: moment().startOf("year").format("YYYY-MM-DD"),
    firstDayOfLastMonth: moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD"),
    lastDayOfLastMonth: moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD"),
    firstDayOfLastYear: moment()
      .subtract(1, "years")
      .startOf("year")
      .format("YYYY-MM-DD"),
    lastDayOfLastYear: moment()
      .subtract(1, "years")
      .endOf("year")
      .format("YYYY-MM-DD"),
    day1: moment().subtract(1, "days").format("YYYY-MM-DD"),
    day7: moment().subtract(7, "days").format("YYYY-MM-DD"),
    day30: moment().subtract(30, "days").format("YYYY-MM-DD"),
    isModalVisible: false,
  };

  fetchData = async () => {
    try {
      const coupon_id = this.props.coupon_id;
      const res = await axios.post(`/coupon/get-users-against-coupon`, { coupon_id });
      this.setState({
        data: res.data.data.users,
        load: true,
      });
    } catch (error) {
      if (error?.response?.data?.code === 401) {
        this.setState({ errorMessage: "Session Expired! Login Again" });
        localStorage.clear();
        window.location = "/login";
      } else if (error?.response?.data?.code !== 401) {
        // this.setState({ errorMessage: error.response.data.message })
        message.error(error?.response?.data?.message);
      }
    }
  };

  componentDidMount() {
    if(this.props.coupon_id) {
      this.fetchData();
    }
  }

  render() {
    const { xScroll, yScroll, ...state } = this.state;
    const scroll = {};
    if (yScroll) {
      scroll.y = 240;
    }
    if (xScroll) {
      scroll.x = "100vw";
    }

    const tableColumns = columns.map((item) => ({
      ...item,
      ellipsis: state.ellipsis,
    }));
    if (xScroll === "fixed") {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = "right";
    }

    return (
      <>
          <div
            className="mt-3"
            style={{ marginLeft: "15px", marginRight: "15px" }}
          >
            <Table
              {...this.state}
              columns={tableColumns}
              dataSource={state.hasData ? this.state.data : null}
              rowKey="_id"
              rowSelection={{
                type: state.selectionType,
                ...rowSelection,
              }}
              loading={{
                indicator: (
                  <div>
                    <Spin indicator={antIcon} />
                  </div>
                ),
                spinning: !this.state.load,
              }}
              scroll={scroll}
              className="table-responsive"
              pagination={false}
            />
        </div>
        {this.state.errorMessage !== null &&
          notification["error"]({
            message: "Error",
            description: this.state.errorMessage,
            placement: "bottomLeft",
          })}
      </>
    );
  }
}
export default Users;
