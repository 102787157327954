import Sidebar from "../Sidebar";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import moment from "moment";

const EditUser = () => {
  const { _id } = useParams();
  const initialstate = {
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    username: "",
    usernameError: "",
    email: "",
    emailError: "",
    phone: "",
    phoneError: "",
    date: "",
    dateError: "",
    state: "",
    stateError: "",
    image: "",
    packageStatus: "",
    packageId: "",
    country: "",
    countryError: "",
    password: "",
    passwordError: "",
    role: "",
    roleError: "",
  };

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [DetailData, setDetailData] = useState(initialstate);
  // eslint-disable-next-line
  const {
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    username,
    usernameError,
    email,
    emailError,
    image,
    date,
    dateError,
    phoneError,
    state,
    stateError,
    country,
    countryError,
    password,
    passwordError,
    role,
    roleError,
    packageId,
    packageStatus,
    phone,
  } = DetailData;

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setDetailData({
      ...DetailData,
      [name]: value,
    });
  };
  const SubmitUser = () => {
    if (validate()) {
      var axios = require("axios");
      var FormData = require("form-data");
      var data = new FormData();
      data.append("firstName", firstName);
      data.append("lastName", lastName);
      data.append("email", email);
      data.append("password", password);
      data.append("role", role);
      // data.append("username", username);
      data.append("phone", "phone");
      data.append("packageID", packageId);
      data.append("packageStatus", packageStatus);
      data.append("country", country);
      data.append("state", state);
      data.append("date_of_birth", date);
      data.append("image", selectedFile === undefined ? image : selectedFile);

      var config = {
        method: "put",
        url: `users/${_id}`,
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response) {
            message.success("User Updated Successfully");
            localStorage.setItem("user", JSON.stringify(response.data.data));
            window.location = "/user/edit/" + _id;
          }
        })
        .catch(function (error) {});
    }
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async (value, keyword) => {
    try {
      const res = await Promise.all([axios.get(`users/profile/${_id}`)]);
      setDetailData({
        ...DetailData,
        firstName: res[0].data.user.firstName,
        lastName: res[0].data.user.lastName,
        // username: res[0].data.user.username,
        email: res[0].data.user.email,
        state: res[0].data.user.state,
        country: res[0].data.user.country,
        role: res[0].data.user.role,
        image: res[0].data.user.image,
        packageId: res[0].data.user.packageID,
        packageStatus: res[0].data.user.packageStatus,
        phone: res[0].data.user.phone,
        load: true,
        date: moment(res[0].data.user.date_of_birth).format("YYYY-MM-DD"),
      });
    } catch {
      // setDetailData({
      //     ...DetailData,
      //     errorMessage: "Something went wrong"
      // })
      message.error("Server Error");
    }
  };

  function validate() {
    let firstNameError = "";
    let lastNameError = "";
    let usernameError = "";
    let emailError = "";
    let dateError = "";
    let countryError = "";
    let stateError = "";
    let passwordError = "";
    let phoneError = "";
    let roleError = "";
    if (!firstName) {
      firstNameError = "First Name is required";
    }
    if (!lastName) {
      lastNameError = "Last Name is required";
    }
    // if (!username) {
    //   usernameError = "Username is required";
    // }
    // if (!date) {
    //     dateError = "DOB is required";
    // }
    if (!country) {
      countryError = "Country is required";
    }
    if (!state) {
      stateError = "State is required";
    }
    if (!phone) {
      phoneError = "Phone No is required";
    }
    if (!role) {
      roleError = "Role is required";
    }
    // eslint-disable-next-line
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email || reg.test(email) === false) {
      emailError = "Invalid Email";
    }
    // if (!password) {
    //     passwordError = "Password is required";
    // }
    if (
      emailError ||
      firstNameError ||
      lastNameError ||
      usernameError ||
      emailError ||
      countryError ||
      stateError ||
      phoneError ||
      roleError
    ) {
      setDetailData({
        ...DetailData,
        firstNameError,
        lastNameError,
        usernameError,
        emailError,
        dateError,
        countryError,
        stateError,
        passwordError,
        phoneError,
        roleError,
      });
      return false;
    }
    // else {
    //     setDetailData({
    //         ...DetailData,
    //         firstName: "",
    //         lastName: "",
    //         username: "",
    //         email: "",
    //         date: "",
    //         country: "",
    //         state: "",
    //         password: "",
    //         phone: "",
    //         role: ""
    //     })
    // }
    return true;
  }
  return (
    <>
      <Sidebar />
      <div className="content pt-3 pl2">
        <Header />
        <h2 className="ml-2 mt-3">Edit User</h2>
        <div className="displayFlex mt-4 ml-2">
          <div className="displayFlex">
            {/* <UploadFile /> */}
            <div>
              {selectedFile === undefined ? (
                <img src={image} alt="imagee" className="avatar mr-3" />
              ) : (
                <img src={preview} alt="" className="avatar mx-3" />
              )}
              <label
                htmlFor="files"
                className="btn"
                style={{
                  backgroundColor: "#F2F8FF",
                  border: "1px solid #fe6f35",
                  color: "black",
                }}
              >
                Upload Photo
              </label>
              <input
                type="file"
                id="files"
                onChange={onSelectFile}
                style={{ color: "white", visibility: "hidden" }}
              />
            </div>
          </div>
        </div>
        <div className=" mt-4">
          <div className="row col-12 ml-2 px-0">
            <div className="row col-lg-8 col-12 d-flex" style={{ gap: "8px" }}>
              <div
                className="col-md-5 col-12 px-0"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="" className="text-black ">
                  First Name
                </label>
                <input
                  placeholder="First Name"
                  name="firstName"
                  value={firstName}
                  onChange={onHandleChange}
                  className="w-100 fillColor px-2 my-1 py-2"
                />
                <span className="text-danger w-100 mt-2 px-2 py-2">
                  {firstNameError}
                </span>
              </div>
              <div
                className="col-md-5 col-12 px-0"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="" className="text-black">
                  Last Name
                </label>
                <input
                  placeholder="Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={onHandleChange}
                  className="w-100 fillColor px-2 my-1 py-2"
                />
                <span className="text-danger w-100 mt-2 px-2 py-2">
                  {lastNameError}
                </span>
              </div>
              {/* <div
                className="col-md-5 col-12 px-0"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="" className="text-black">
                  User Name
                </label>
                <input
                  placeholder="Username"
                  name="username"
                  value={username}
                  onChange={onHandleChange}
                  className="w-100 mt-2 fillColor px-2 py-2 my-1"
                />
                <span className="text-danger w-100 mt-2 px-2 py-2">
                  {usernameError}
                </span>
              </div> */}
              <div
                className="col-md-5 col-12 px-0"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="" className="text-black">
                  Email
                </label>
                <input
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={onHandleChange}
                  className="w-100 mt-2 fillColor px-2 py-2 my-1"
                />
                <span className="text-danger w-100 mt-2 px-2 py-2">
                  {emailError}
                </span>
              </div>
              <div
                className="col-md-5 col-12 px-0"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="" className="text-black">
                  Date
                </label>
                <input
                  type="date"
                  onChange={onHandleChange}
                  name="date"
                  value={date}
                  className="w-100 mt-2 fillColor px-2 py-2 my-1"
                />
                <span className="text-danger w-100 mt-2 px-2 py-2">
                  {dateError}
                </span>
              </div>
              <div
                className="col-md-5 col-12 px-0"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="" className="text-black">
                  State
                </label>
                <input
                  placeholder="State"
                  name="state"
                  value={state}
                  onChange={onHandleChange}
                  className="w-100 mt-2 fillColor px-2 py-2 my-1"
                />
                <span className="text-danger w-100 mt-2 px-2 py-2">
                  {stateError}
                </span>
              </div>
              <div
                className="col-md-5 col-12 px-0"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="" className="text-black">
                  Country
                </label>
                <input
                  placeholder="Country"
                  name="country"
                  value={country}
                  onChange={onHandleChange}
                  className="w-100 mt-2 fillColor px-2 py-2 my-1"
                />
                <span className="text-danger w-100 mt-2 px-2 py-2">
                  {countryError}
                </span>
              </div>
              <div
                className="col-md-5 col-12 px-0"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="" className="text-black">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onHandleChange}
                  placeholder="Password"
                  className="w-100 mt-2 fillColor px-2 py-2 my-1"
                />
                <span className="text-danger w-100 mt-2 px-2 py-2">
                  {passwordError}
                </span>
              </div>
              <div
                className="col-md-5 col-12 px-0"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="" className="text-black">
                  Phone
                </label>
                <input
                  placeholder="Phone"
                  name="phone"
                  type="number"
                  value={phone}
                  onChange={onHandleChange}
                  className="w-100 mt-2 fillColor px-2 py-2 my-1"
                />
                <span className="text-danger w-100 mt-2 px-2 py-2">
                  {phoneError}
                </span>
              </div>
              <div
                className="col-md-5 col-12 px-0"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="" className="text-black">
                  Role
                </label>
                <select
                  onChange={onHandleChange}
                  name="role"
                  className="w-100 mt-2 fillColor px-2 py-2 my-1"
                >
                  <option value={role ? role : ""} className="blue">
                    {role ? role : "Role"}
                  </option>
                  <option value="user" className="blue">
                    User
                  </option>
                  <option value="editor" className="blue">
                    Editor
                  </option>
                  <option value="admin" className="blue">
                    Admin
                  </option>
                </select>
                <span className="text-danger w-100 mt-2 px-2 py-2">
                  {roleError}
                </span>
              </div>
              {/* </div> */}
              {/* <div className='row '> */}
            </div>
          </div>
          <button
            onClick={SubmitUser}
            className="btn float-left Radius8 White py-2 my-1 px-3 mt-2 ml-2"
          >
            Submit
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default EditUser;
