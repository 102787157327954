import React, { useState, useEffect } from "react";
import { ReactComponent as OkIcon } from "../../assests/ok.svg";
import { ReactComponent as PlusIcon } from "../../assests/plus.svg";
import Sidebar from "../Sidebar";
import axios from "axios";
import { message, notification } from "antd";
import { CloseCircleFilled, LoadingOutlined } from "@ant-design/icons";
import Footer from "../Footer";
import Header from "../Header";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const EditPackage = (props) => {
  const [state, setState] = useState({
    name: "",
    packageType: "",
    stripe_package_type: "",
    price: "",
    free: "",
    options: [],
    errorMessage: null,
    data: null,
    loading: false,
  });

  useEffect(() => {
    const link = "package/" + props.match.params._id;
    axios
      .get(link)
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          name: response.data.packageFound.name,
          price: response.data.packageFound.price,
          options: response.data.packageFound.options,
          packageType: response.data.packageFound.packageType,
          stripe_package_type: response.data.packageFound?.stripe_package_type,
        }));
      })
      .catch(function (error) {
        message.error(error);
      });
  }, [props.match.params._id]);

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const FreeService = () => {
    if (state.free !== "") {
      const newService = state.free;
      setState((prevState) => ({
        ...prevState,
        options: [...prevState.options, newService],
        free: "",
      }));
    }
  };

  const removeFreeService = (index) => {
    const myArr = state.options.filter((item, i) => i !== index);
    setState((prevState) => ({
      ...prevState,
      options: myArr,
    }));
  };

  const changeOptions = (e, index) => {
    const myArr = [...state.options];
    myArr[index] = e.target.value;
    setState((prevState) => ({
      ...prevState,
      options: myArr,
    }));
  };

  const SubmitPackage = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const link = "package/" + props.match.params._id;

    axios
      .put(link, {
        name: state.name,
        packageType: state.packageType,
        stripe_package_type: state.stripe_package_type,
        price: state.price,
        options:
          state.free === "" ? state.options : [...state.options, state.free],
      })
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));

        if (res.data.success === true) {
          message.success("Package Added Successfully");
          window.location = "/packages";
        }
      })
      .catch(function (error) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));

        // window.location = "/packages";
        message.error(error.response.data.message);
      });
  };

  const RenderFree = state.options.map((servicemap, index) => (
    <div key={index}>
      <table>
        <tbody>
          <tr>
            <td>
              <OkIcon />
            </td>
            <td>
              <input
                value={servicemap}
                onChange={(e) => changeOptions(e, index)}
                className="col-12 border-0 fillColor px-md-5 px-1 py-2"
              />
            </td>
            <td>
              <button
                className="btn"
                style={{ marginTop: "4px" }}
                type="button"
                onClick={() => removeFreeService(index)}
              >
                {" "}
                <CloseCircleFilled />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ));

  return (
    <>
      <Sidebar />
      <div className="content pt-3 pl2">
        <Header />
        <div className="displayFlex pt-3">
          <div className="displayFlex ml-2">
            <h2
              className="mr-auto font-weight-bold"
              style={{ marginLeft: "8px" }}
            >
              Packages & Pricing
            </h2>
          </div>
        </div>
        <div className="col-12 my-3 row mx-0">
          <div className="col-md-6 col-12 px-0">
            <div className=" col-12 mt-2 px-0">
              <input
                value={state.name}
                name="name"
                onChange={onHandleChange}
                className="col-12 fillColor py-2"
              />
            </div>
            <div className=" col-12 mt-2 px-0">
              <select
                name="packageType"
                onChange={onHandleChange}
                className="col-12 fillColor py-2"
              >
                <option value="">{state.packageType}</option>
                <option value="Free">Free</option>
                <option value="Paid">Paid</option>
              </select>
            </div>
            <div className=" col-12 mt-2 px-0">
              <select
                name="stripe_package_type"
                onChange={onHandleChange}
                className="col-12 fillColor py-2"
              >
                <option value="">{state.stripe_package_type}</option>
                <option value="free">Free</option>
                <option value="month">Monthly</option>
                <option value="year">Yearly</option>
              </select>
            </div>
            <div className=" col-12 mt-2 px-0">
              <input
                value={state.price}
                type="number"
                name="price"
                onChange={onHandleChange}
                className="col-12 fillColor py-2"
              />
            </div>
            <div className=" col-12 mt-2 px-0">
              <input
                placeholder="Option"
                required
                name="free"
                value={state.free}
                onChange={onHandleChange}
                className="col-12 fillColor py-2"
              />
              <div className=" col-12 ">
                <button
                  type="button"
                  className="btn d-block mx-auto White outline"
                  onClick={FreeService}
                >
                  <PlusIcon />{" "}
                </button>
              </div>
            </div>
            <div className="col-md-12 mt-2 px-0">
              <button
                onClick={SubmitPackage}
                className="White Radius8 p-2 d-block mx-auto"
              >
                {state.loading ? antIcon : "Update Package"}
              </button>
            </div>
          </div>
          <div className="col-md-5 col-12 mx-auto">
            <h6 className="text-left font-weight-bold">Options</h6>
            {state.options?.length > 0 ? (
              <div className="text-center">{RenderFree}</div>
            ) : (
              <p className="text-left">No Options Added Yet</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
      {state.errorMessage !== null &&
        notification["error"]({
          message: "Error",
          description: state.errorMessage,
          placement: "bottomLeft",
        })}
    </>
  );
};

export default EditPackage;
