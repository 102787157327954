import React, { useCallback, useEffect, useState } from "react";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import Header from "../Header";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { NavLink } from "react-router-dom";
import REDIRECT_ICON from '../../assests/maximize.png';
import Users from "./Users";

const Coupon = () => {
  const [couponList, setCouponList] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [usersList, setUsersList] = useState([]);

  const getCouponList = useCallback(() => {
    axios
      .get("coupon/get-coupons")
      .then((response) => {
        setCouponList(response.data.data);
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    getCouponList();
  }, [getCouponList]);

  const deleteCoupon = (id) => {
    axios
      .delete(`coupon/${id}`)
      .then((response) => {
        getCouponList();
      })
      .catch((error) => {});
  };

  const handleCouponSelect = (coupon) => {
    setSelectedCoupon(coupon);
  }

  return (
    <>
      <Sidebar />
      <div className="content pt-3">
        <Header />
        {selectedCoupon ? (
           <div className="col-12 pt-3">
           <div className="displayFlex ">
             <div className="displayFlex">
               <h2 className="mr-auto">Coupon </h2>
             </div>
           </div>
 
           <div className="mt-3">
             <p
               style={{
                 fontSize: "20px",
                 fontWeight: "semibold",
               }}
             >
               [{selectedCoupon.coupon_code}] Users
             </p>
             
             {/* USERS TABLE */}
             <Users coupon_id={selectedCoupon._id} />

             <a href="#" onClick={(e) => { e.preventDefault(); setSelectedCoupon(null)}}>
               <button className="btn Radius8 White py-2 mt-2">Back</button>
             </a>
           </div>
         </div>
        ) : (
          <div className="col-12 pt-3">
           <div className="displayFlex ">
             <div className="displayFlex">
               <h2 className="mr-auto">Coupon</h2>
             </div>
           </div>
 
           <div className="mt-3">
             <p
               style={{
                 fontSize: "20px",
                 fontWeight: "semibold",
               }}
             >
               Coupon List
             </p>
             <div
               style={{
                 width: "100%",
                 display: "flex",
                 alignItems: "center",
                 gap: "40px",
                 borderBottom: "1px solid #000",
                 background: "#f5f5f5",
                 padding: "10px 0px",
               }}
             >
               <p
                 style={{
                   fontSize: "16px",
                   fontWeight: "semibold",
                   color: "#000",
                   width: "100%",
                   maxWidth: "180px",
                 }}
               >
                 Coupon
               </p>
               <p
                 style={{
                   fontSize: "16px",
                   fontWeight: "semibold",
                   color: "#000",
                   width: "100%",
                   maxWidth: "180px",
                 }}
               >
                 Coupon Percentage %
               </p>
               <p
                 style={{
                   fontSize: "16px",
                   fontWeight: "semibold",
                   color: "#000",
                   width: "100%",
                   maxWidth: "180px",
                 }}
               >
                 Duration in Months
               </p>
               <p
                 style={{
                   fontSize: "16px",
                   fontWeight: "semibold",
                   color: "#000",
                   width: "100%",
                   maxWidth: "180px",
                 }}
               >
                 Active
               </p>
               <p
                 style={{
                   fontSize: "16px",
                   fontWeight: "semibold",
                   color: "#000",
                   width: "100%",
                   maxWidth: "180px",
                 }}
               >
                 Active Users
               </p>
               <p
                 style={{
                   fontSize: "16px",
                   fontWeight: "semibold",
                   color: "#000",
                   width: "100%",
                   maxWidth: "180px",
                 }}
               >
                 Delete
               </p>
             </div>
             <div
               style={{
                 marginTop: "20px",
                 width: "100%",
                 display: "flex",
                 flexDirection: "column",
                 gap: "20px",
               }}
             >
               {couponList.length &&
                 couponList.map((coupon) => (
                   <div
                     key={coupon._id}
                     style={{
                       width: "100%",
                       display: "flex",
                       alignItems: "center",
                       gap: "40px",
                     }}
                   >
                     <p
                       style={{
                         fontSize: "16px",
                         fontWeight: "semibold",
                         color: "#000",
                         width: "100%",
                         maxWidth: "180px",
                       }}
                     >
                       {coupon?.coupon_code}
                     </p>
                     <p
                       style={{
                         fontSize: "16px",
                         fontWeight: "semibold",
                         color: "#000",
                         width: "100%",
                         maxWidth: "180px",
                       }}
                     >
                       {coupon.percentage}%
                     </p>
                     <p
                       style={{
                         fontSize: "16px",
                         fontWeight: "semibold",
                         color: "#000",
                         width: "100%",
                         maxWidth: "180px",
                       }}
                     >
                       {coupon.duration_in_months}
                     </p>
                     <p
                       style={{
                         fontSize: "16px",
                         fontWeight: "semibold",
                         color: "#000",
                         width: "100%",
                         maxWidth: "180px",
                       }}
                     >
                       {coupon.is_active ? "Yes" : "No"}
                     </p>
                     <p
                       style={{
                         fontSize: "16px",
                         fontWeight: "semibold",
                         color: "#000",
                         width: "100%",
                         maxWidth: "180px",
                       }}
                     >
                       {coupon.countUsers} 
                       <img onClick={(() => handleCouponSelect(coupon) )} style={{ paddingBottom: '3px', paddingLeft: '5px', cursor: 'pointer' }} src={REDIRECT_ICON} alt="redirect icon" />
                     </p>
                     <p
                       style={{
                         cursor: "pointer",
                         fontSize: "16px",
                         fontWeight: "semibold",
                         color: "#000",
                         width: "100%",
                         maxWidth: "180px",
                       }}
                       onClick={() => deleteCoupon(coupon._id)}
                     >
                       <MdDelete />
                     </p>
                   </div>
                 ))}
             </div>
             <NavLink to={"/create-coupon"}>
               <button className="btn Radius8 White py-2 mt-2">Create</button>
             </NavLink>
           </div>
         </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Coupon;
