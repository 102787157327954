import { message, notification, Popconfirm } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { ReactComponent as Downloads } from "../../assests/download.svg";
import { ReactComponent as Clicks } from "../../assests/clicks.svg";
import pdf from "../../assests/pdf.png";
import { LoadingOutlined } from "@ant-design/icons";
import Footer from "../Footer";
import Header from "../Header";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, display: "block" }} spin />
);

const Resources = () => {
  const initialstate = {
    data: "",
    title: "",
    url: "",
    podcastTitle: "",
    podcastUrl: "",
    documentTitle: "",
    documentUrl: "",
    load: false,
    loadPodcast: false,
    loadBooks: false,
    errorMessage: null,
    dataLoad: false,
  };
  const [DetailData, setDetailData] = useState(initialstate);
  const {
    data,
    title,
    url,
    load,
    loadPodcast,
    errorMessage,
    podcastTitle,
    podcastUrl,
    documentTitle,
    documentUrl,
    dataLoad,
    loadBooks,
  } = DetailData;
  const [allPodcast, setAllPodcast] = useState([]);
  const [previewPodcast, setPreviewPodcast] = useState([]);
  const [previewBooks, setPreviewBooks] = useState([]);

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setDetailData({
      ...DetailData,
      [name]: value,
    });
  };

  const fetchData = async (value, keyword) => {
    setDetailData({
      ...DetailData,
      dataLoad: true,
    });
    try {
      const res = await Promise.all([axios.get("resource")]);
      const res2 = await Promise.all([axios.get("podcast")]);
      setDetailData({
        ...DetailData,
        data: res[0].data.resources,
        dataLoad: false,
      });
      setAllPodcast(res2[0].data.podcasts);
    } catch {
      setDetailData({
        ...DetailData,
        dataLoad: false,
      });
      message.error("Server Error");
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const [selectedFile, setSelectedFile] = useState();
  const [podcastFile, setPodcastFile] = useState();
  const [documentFile, setDocumentFile] = useState();
  // eslint-disable-next-line
  const [preview, setPreview] = useState();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!(selectedFile ?? podcastFile ?? documentFile)) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(
      selectedFile ?? podcastFile ?? documentFile
    );
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
    // eslint-disable-next-line
  }, [selectedFile ?? podcastFile ?? documentFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined) ??
        setDocumentFile(undefined) ??
        setPodcastFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]) ??
      setPodcastFile(e.target.files[0]) ??
      setDocumentFile(e.target.files[0]);
  };

  const onSelectBook = (e) => {
    if (previewBooks.length > 0) {
      setPreviewBooks([]);
    }
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreviewBooks((prev) => [...prev, objectUrl]);
    setSelectedFile(e.target.files[0]);
  };

  const onSelectPodcast = (e) => {
    if (previewPodcast.length > 0) {
      setPreviewPodcast([]);
    }
    if (!e.target.files || e.target.files.length === 0) {
      setPodcastFile(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreviewPodcast((prev) => [...prev, objectUrl]);
    setPodcastFile(e.target.files[0]);
  };

  const SubmitResource = (resourceType) => {
    // let resourceTitle = (title && title) ?? (podcastTitle && podcastTitle) ?? (documentTitle && documentTitle);
    let resourceTitle;
    if (title !== "") {
      resourceTitle = title;
    } else if (podcastTitle !== "") {
      resourceTitle = podcastTitle;
    } else if (documentTitle !== "") {
      resourceTitle = documentTitle;
    }

    if (resourceType === "book") {
      setDetailData({
        ...DetailData,
        loadBooks: true,
      });
    } else {
      setDetailData({
        ...DetailData,
        load: true,
      });
    }
    let formData = new FormData();
    formData.append("title", resourceTitle);
    formData.append("resourceType", resourceType);
    formData.append("resourceUrl", url);
    formData.append("file", selectedFile);
    const link = `resource`;
    axios
      .post(link, formData)
      .then((res) => {
        if (res.data.success) {
          message.success("Resource Added Successfully");
          window.location = "/resources";
          setPreviewBooks([]);
        }
      })
      .catch(function (error) {
        message.error("Something went wrong");
        setDetailData({
          ...DetailData,
          load: false,
          loadBooks: false,
        });
      });
  };

  const SubmitPodcast = () => {
    // let resourceTitle = (title && title) ?? (podcastTitle && podcastTitle) ?? (documentTitle && documentTitle);

    setDetailData({
      ...DetailData,
      loadPodcast: true,
    });
    let formData = new FormData();
    formData.append("title", podcastTitle);
    formData.append("podcastType", "radio");
    formData.append("podcastUrl", podcastUrl);
    formData.append("file", podcastFile);
    const link = `podcast`;
    axios
      .post(link, formData)
      .then((res) => {
        if (res.data.success) {
          message.success("Podcast Added Successfully");
          window.location = "/resources";
          setPreviewPodcast([]);
        }
      })
      .catch(function (error) {
        message.error("Something went wrong");
        setDetailData({
          ...DetailData,
          loadPodcast: false,
        });
      });
  };
  const DeleteResource = (id) => {
    const link = "resource/" + id;
    axios
      .delete(link)
      .then((res) => {
        if (res.data.success) {
          message.success("Resource Deleted Successfully");
        }
        fetchData();
      })
      .catch(function (error) {
      });
  };

  const DeletePodcast = (id) => {
    const link = "podcast/" + id;
    axios
      .delete(link)
      .then((res) => {
        if (res.data.success) {
          message.success("Podcast Deleted Successfully");
        }
        fetchData();
      })
      .catch(function (error) {
      });
  };

  return (
    <>
      <Sidebar />
      <div className="content pt-3 pl2">
        <Header />
        <h2 className="pt-3">Resources</h2>
        <div className="offBlue py-3 bodeerr">
          <h4 className="pt-2 ml-3">Books</h4>
          <div className="row col-12 mx-0 offBlue">
            <h6 className="pt-3">Add Book</h6>
            <input
              className="fillColor mx-2 py-2"
              placeholder="Add Book Title"
              name="title"
              value={title}
              onChange={onHandleChange}
            />
            <input
              className="fillColor mx-2 py-2"
              placeholder="Add Book url"
              name="url"
              value={url}
              onChange={onHandleChange}
            />
            <div className="mt-1">
              <label
                htmlFor="books"
                className="btn"
                style={{
                  backgroundColor: "#F2F8FF",
                  border: "1px solid #fe6f35",
                  color: "black",
                }}
              >
                Upload File
              </label>
              <input
                type="file"
                id="books"
                onChange={onSelectBook}
                style={{
                  color: "white",
                  visibility: "hidden",
                  display: "none",
                }}
              />
              <button
                className="btn btn-primary ml-2 px-2"
                style={{ marginTop: "-0.5rem" }}
                onClick={() => SubmitResource("book")}
              >
                {loadBooks === true ? antIcon : "Publish"}
              </button>
            </div>
          </div>

          {dataLoad === true ? (
            antIcon
          ) : (
            <div className="row col-12 mx-auto my-3 d-flex justify-content-center">
              {data && data.length > 0 ? (
                data.map((item) => {
                  return (
                    <>
                      {item.resourceType === "book" && (
                        <div
                          className="mx-2 mt-2"
                          style={{
                            width: "148px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="img-wrap">
                            <Popconfirm
                              title="Sure to delete?"
                              onConfirm={() => DeleteResource(item._id)}
                            >
                              <span className="close">&times;</span>
                            </Popconfirm>

                            {item.fileUrl.includes(".pdf") ? (
                              <object
                                data={item.fileUrl}
                                type="application/pdf"
                                height="170px"
                                width="148px"
                              >
                                <iframe
                                  src={item.fileUrl}
                                  height="170px"
                                  width="148px"
                                  title="pdf"
                                >
                                  <p>This browser does not support PDF!</p>
                                </iframe>
                              </object>
                            ) : (
                              <img
                                src={item.fileUrl}
                                alt="imgg"
                                className="px-2"
                                height="170px"
                                width="148px"
                              ></img>
                            )}
                          </div>
                          <p
                            className="text-center"
                            style={{
                              breakWord: "break-word",
                            }}
                          >
                            {item.title}
                          </p>
                          <div className="ml-3">
                            <span className="">
                              <Downloads className="mr-1" />
                              {item.noOfDownloads}
                            </span>
                            <span className="ml-4">
                              <Clicks className="mr-1" />
                              {item.noOfClicks}
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })
              ) : (
                <p className="text-center">No Books Available</p>
              )}
              {previewBooks &&
                previewBooks.length > 0 &&
                previewBooks.map((item) => {
                  return (
                    <div
                      key={item}
                      className="mx-2 mt-2"
                      style={{
                        width: "148px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="img-wrap">
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => setPreviewBooks([])}
                        >
                          <span className="close">&times;</span>
                        </Popconfirm>
                        {selectedFile.name.includes(".pdf") ? (
                          <object
                            data={item}
                            type="application/pdf"
                            height="170px"
                            width="148px"
                          >
                            <iframe
                              src={item}
                              height="170px"
                              width="148px"
                              title="pdf"
                            >
                              <p>This browser does not support PDF!</p>
                            </iframe>
                          </object>
                        ) : (
                          <img
                            src={item}
                            alt="imgg"
                            className="px-2"
                            height="170px"
                            width="148px"
                          ></img>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div className="offBlue py-3 my-3 bodeerr">
          <h4 className="pt-2 ml-3">Podcasts</h4>
          <div className="row col-12 mx-0 offBlue">
            <h6 className="pt-3">Add Podcast</h6>
            <input
              className="fillColor mx-2 py-2"
              placeholder="Add Podcast Title"
              name="podcastTitle"
              value={podcastTitle}
              onChange={onHandleChange}
            />
            <input
              className="fillColor mx-2 py-2"
              placeholder="Add Podcast url"
              name="podcastUrl"
              value={podcastUrl}
              onChange={(e) =>
                setDetailData((prev) => {
                  return { ...prev, podcastUrl: e.target.value };
                })
              }
            />
            <div className="mt-1">
              <label
                htmlFor="podcast"
                className="btn"
                style={{
                  backgroundColor: "#F2F8FF",
                  border: "1px solid #fe6f35",
                  color: "black",
                }}
              >
                Upload File
              </label>
              <input
                type="file"
                id="podcast"
                onChange={onSelectPodcast}
                style={{
                  color: "white",
                  visibility: "hidden",
                  display: "none",
                }}
              />
              <button
                className="btn btn-primary ml-2 px-2"
                style={{ marginTop: "-0.5rem" }}
                onClick={SubmitPodcast}
              >
                {loadPodcast === true ? antIcon : "Publish"}
              </button>
            </div>
          </div>
          {dataLoad === true ? (
            antIcon
          ) : (
            <div className="row col-11 mx-auto my-3 d-flex justify-content-center">
              {allPodcast && allPodcast.length > 0 ? (
                allPodcast.map((item) => {
                  return (
                    <div
                      className="mx-2 mt-2"
                      style={{
                        width: "148px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="img-wrap">
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => DeletePodcast(item._id)}
                        >
                          <span className="close">&times;</span>
                        </Popconfirm>
                        {item.fileUrl.includes(".pdf") ? (
                          <object
                            data={item.fileUrl}
                            type="application/pdf"
                            height="170px"
                            width="148px"
                          >
                            <iframe
                              src={item.fileUrl}
                              height="170px"
                              width="148px"
                              title="pdf"
                            >
                              <p>This browser does not support PDF!</p>
                            </iframe>
                          </object>
                        ) : (
                          <img
                            src={item.fileUrl}
                            alt="imgg"
                            className="px-2"
                            height="170px"
                            width="148px"
                          ></img>
                        )}
                      </div>
                      <p className="text-center">{item.title}</p>
                      <div className="ml-3">
                        <span className="">
                          <Downloads className="mr-1" />
                          {item.noOfDownloads}
                        </span>
                        <span className="ml-4">
                          <Clicks className="mr-1" />
                          {item.noOfClicks}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-center">No Podcasts Available</p>
              )}
              {previewPodcast &&
                previewPodcast.length > 0 &&
                previewPodcast.map((item) => {
                  return (
                    <div
                      key={item}
                      className="mx-2 mt-2"
                      style={{
                        width: "148px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="img-wrap">
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => setPreviewPodcast([])}
                        >
                          <span className="close">&times;</span>
                        </Popconfirm>
                        {podcastFile.name.includes(".pdf") ? (
                          <object
                            data={item}
                            type="application/pdf"
                            height="170px"
                            width="148px"
                          >
                            <iframe
                              src={item}
                              height="170px"
                              width="148px"
                              title="pdf"
                            >
                              <p>This browser does not support PDF!</p>
                            </iframe>
                          </object>
                        ) : (
                          <img
                            src={item}
                            alt="imgg"
                            className="px-2"
                            height="170px"
                            width="148px"
                          ></img>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div className="offBlue py-3 my-3 bodeerr">
          <h4 className="pt-2 ml-3">Documents</h4>
          <div className="row col-12 mx-0 offBlue">
            <h6 className="pt-3">Add Document</h6>
            <input
              className="fillColor mx-2 py-2"
              placeholder="Add Document Title"
              name="documentTitle"
              value={documentTitle}
              onChange={onHandleChange}
            />
            <input
              className="fillColor mx-2 py-2"
              placeholder="Add Document url"
              name="documentUrl"
              value={documentUrl}
              onChange={onHandleChange}
            />
            <div className="mt-1">
              <label
                htmlFor="files"
                className="btn"
                style={{
                  backgroundColor: "#F2F8FF",
                  border: "1px solid #fe6f35",
                  color: "black",
                }}
              >
                Upload File
              </label>
              <input
                type="file"
                id="files"
                onChange={onSelectFile}
                style={{
                  color: "white",
                  visibility: "hidden",
                  display: "none",
                }}
              />
              <button
                className="btn btn-primary ml-2 px-2"
                style={{ marginTop: "-0.5rem" }}
                onClick={() => SubmitResource("pdf")}
              >
                {load === true ? antIcon : "Publish"}
              </button>
            </div>
          </div>
          {dataLoad === true ? (
            antIcon
          ) : (
            <div className="row col-11 mx-auto my-3">
              {data &&
                data?.length > 0 &&
                data.map((item) => {
                  return (
                    <>
                      {item.resourceType === "pdf" && (
                        <div className="mx-2">
                          <div className="img-wrap">
                            <Popconfirm
                              title="Sure to delete?"
                              onConfirm={() => DeleteResource(item._id)}
                            >
                              <span className="close">&times;</span>
                            </Popconfirm>
                            <object
                              data={pdf}
                              type="application/pdf"
                              className="px-2"
                              height="50%"
                              width="148px"
                            >
                              <iframe
                                src={pdf}
                                className="px-2"
                                height="50%"
                                width="148px"
                                title="pdf"
                              >
                                <p>This browser does not support PDF!</p>
                              </iframe>
                            </object>
                            {/* <object
                              data={pdf}
                              type="application/pdf"
                              className="px-2"
                              height="50%"
                              width="148px"
                            >
                              <p>
                                Alternative text - include a link{" "}
                                <a href={pdf}>to the PDF!</a>
                              </p>
                            </object> */}
                            {/* <img src={pdf} alt="imgg"></img> */}
                          </div>
                          <p className="text-center">{item.title}</p>
                          <div className="ml-3">
                            <span className="">
                              <Downloads className="mr-1" />
                              {item.noOfDownloads}
                            </span>
                            <span className="ml-4">
                              <Clicks className="mr-1" />
                              {item.noOfClicks}
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <Footer />
      {errorMessage !== null &&
        notification["error"]({
          message: "Error",
          description: errorMessage,
          placement: "bottomLeft",
        })}
    </>
  );
};

export default Resources;
