/* eslint-disable no-unused-vars */
import Sidebar from "../Sidebar";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { message } from "antd";
import Footer from "../Footer";
import Header from "../Header";
import { CgSpinner } from "react-icons/cg";

const UpgradePackage = () => {
  const { _id } = useParams();

  const [email, setEmail] = useState("");
  const [packageData, setPackageData] = useState([]);
  const [selectPackage, setSelectPackage] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const res = await Promise.all([axios.get(`users/profile/${_id}`)]);
      setEmail(res[0].data.user.email);
    } catch {
      message.error("Server Error");
    }
  }, [_id]);

  const fetchPackage = useCallback(async () => {
    try {
      const res = await Promise.all([axios.get("package")]);
      if (res[0]?.data.packages) {
        const filterData = res[0]?.data.packages.filter(
          (item) => item.packageType === "Paid"
        );
        setPackageData(filterData);
      }
    } catch {
      // this.setState({ errorMessage: "Something went Wrong" })
      message.error("Server Error");
    }
  }, []);

  useEffect(() => {
    fetchData();
    fetchPackage();
  }, [fetchData, fetchPackage]);

  const handleUpgrade = async () => {
    setLoading(true);
    try {
      const res = await Promise.all([
        axios.post("package/user-add-free-subscription", {
          email: email,
          package_id: selectPackage,
        }),
      ]);
      if (res[0].data.success) {
        message.success("Package Upgrade Successfully");
        window.location.href = "/users";
        setLoading(false);
      }
    } catch {
      setLoading(false);
      message.error("Server Error");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content pt-3 pl2">
        <Header />
        <h2 className="pt-3">Upgrade Package</h2>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: "20px",
            gap: "20px",
          }}
        >
          {packageData.map((item) => (
            <div
              style={{
                width: "100px",
                height: "50px",
                backgroundColor:
                  selectPackage === item._id ? "#FE6F35" : "transparent",
                border: "1px solid #FE6F35",
                color: selectPackage === item._id ? "black" : "#FE6F35",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => setSelectPackage(item._id)}
            >
              {item.name}
            </div>
          ))}
          <button
            style={{
              width: "100px",
              height: "50px",
              backgroundColor: "#FE6F35",
              color: "black",
              borderRadius: "5px",
              border: "1px solid #FE6F35",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: selectPackage === "" ? "0.5" : "1",
            }}
            disabled={selectPackage === "" ? true : false}
            onClick={handleUpgrade}
          >
            {loading ? <CgSpinner className="animate-spin w-5" /> : "Upgrade"}
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default UpgradePackage;
