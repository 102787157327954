class CustomUploadAdapter {
  constructor(loader, uploadUrl) {
    this.loader = loader;
    this.uploadUrl = uploadUrl;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._uploadFile(file, resolve, reject);
        })
    );
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _uploadFile(file, resolve, reject) {
    const data = new FormData();
    data.append("image", file);
    const token = localStorage.getItem("x-auth-token");

    this.xhr = new XMLHttpRequest();
    this.xhr.open("POST", this.uploadUrl, true);
    this.xhr.responseType = "json";

    this.xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    this.xhr.setRequestHeader("Access-Control-Allow-Origin", "*");

    this.xhr.addEventListener("load", () => {
      if (this.xhr.status >= 200 && this.xhr.status < 300) {
        resolve({
          default: this.xhr.response.data[0],
        });
      } else {
        reject(this._genericErrorText());
      }
    });

    this.xhr.addEventListener("error", () => reject(this._genericErrorText()));
    this.xhr.addEventListener("abort", () => reject());

    if (this.xhr.upload) {
      this.xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          this.loader.uploadTotal = evt.total;
          this.loader.uploaded = evt.loaded;
        }
      });
    }

    this.xhr.send(data);
  }

  _genericErrorText() {
    return `Couldn't upload file: ${this.loader.file.name}.`;
  }
}

export default function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new CustomUploadAdapter(
      loader,
      `${process.env.REACT_APP_APP_BASE_URL}article/upload-image`
    ); // Replace with your backend URL
  };
}
