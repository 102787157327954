import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

const Graph = React.memo(({ x, y, title, color, count }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const newSeries = [
      {
        name: title,
        data: y,
        color: color,
      },
    ];
    setSeries(newSeries);
  }, [y, color, title]);

  const options = useMemo(
    () => ({
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      xaxis: {
        type: "datetime",
        categories: x,
      },
      tooltip: {
        x: {
          format: "dd/MM/yyyy",
        },
      },
    }),
    [x]
  );

  return (
    <div className="ant-table-wrapper mt-3">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginTop: "10px",
          marginLeft: "10px",
        }}
      >
        <h6 className="m-0" style={{ fontWeight: "bold", fontSize: "20px" }}>
          {title}
        </h6>
        {title !== "Income Generated" && count && (
          <p
            style={{
              fontSize: "20px",
              color: "black",

              margin: "0",
              fontWeight: "bold",
            }}
          >
            {count}
          </p>
        )}
      </div>

      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
});

Graph.propTypes = {
  x: PropTypes.arrayOf(PropTypes.string).isRequired,
  y: PropTypes.arrayOf(PropTypes.number).isRequired,
  Members: PropTypes.arrayOf(PropTypes.object),
};

Graph.defaultProps = {
  Members: [],
};

export default Graph;
