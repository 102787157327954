import Sidebar from "../Sidebar";
import { Button, message, Space } from "antd";
import React, { useState } from "react";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import Footer from "../Footer";
import Header from "../Header";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const AddPage = () => {
  const onFinish = () => {
    message.success("Submit success!");
  };

  const initialstate = {
    metaTitle: "",
    metaDescription: "",
    metaUrl: "",
    metaUrlError: "",
    metaTitleError: "",
    descriptionError: "",
  };

  const [load, setLoad] = useState(false);
  const [DetailData, setDetailData] = useState(initialstate);
  const {
    metaTitle,
    metaDescription,
    metaUrl,
    metaTitleError,
    descriptionError,
    metaUrlError,
  } = DetailData;

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setDetailData({
      ...DetailData,
      [name]: value,
    });
  };
  function validate() {
    let metaTitleError = "";
    let descriptionError = "";
    let metaUrlError = "";
    if (!metaTitle) {
      metaTitleError = "Meta Title is required";
    }
    if (!metaDescription) {
      descriptionError = "Meta Description is required";
    }
    if (!metaUrl) {
      metaUrlError = "Page URL is required";
    }
    if (metaTitleError || descriptionError || metaUrlError) {
      setDetailData({
        ...DetailData,
        metaTitleError,
        descriptionError,
        metaUrlError,
      });
      return false;
    } else {
      setDetailData({
        ...DetailData,
        metaDescription: "",
        metaTitle: "",
        metaUrl: "",
      });
    }
    return true;
  }
  const SubmitPage = () => {
    if (validate()) {
      setLoad(true);
      const link = "page";
      axios
        .post(link, {
          title: metaTitle,
          description: metaDescription,
          url: metaUrl,
        })
        .then((res) => {
          if (res.data.success) {
            message.success("Page Added Successfully");
            window.location = "/pages/add";
          } else {
            message.error(res.data.message);
            setLoad(true);
          }
        });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content pt-3 pl2">
        <Header />
        <h2 className="pt-3">Add Page</h2>
        <div className="displayFlex mt-4"></div>
        <div className="mt-2">
          <div className="">
            <form onSubmit={onFinish}>
              <div className="col-md-6 col-12 mt-2 px-0">
                <input
                  placeholder="Meta Title"
                  required
                  name="metaTitle"
                  value={metaTitle}
                  onChange={onHandleChange}
                  className="col-12 fillColor py-2"
                />
                <span className="text-danger">{metaTitleError}</span>
              </div>
              <div className="col-md-6 col-12 mt-2 px-0">
                <input
                  placeholder="Page Url"
                  required
                  name="metaUrl"
                  value={metaUrl}
                  onChange={onHandleChange}
                  className="col-12 fillColor py-2"
                />
                <span className="text-danger">{metaUrlError}</span>
              </div>

              <div className="col-md-6 col-12 my-2 px-0">
                <input
                  placeholder="Meta Description ()"
                  required
                  name="metaDescription"
                  value={metaDescription}
                  onChange={onHandleChange}
                  className="col-12 fillColor py-2"
                />
                <span className="text-danger">{descriptionError}</span>
              </div>

              <div className="col-md-6 col-12 px-0">
                <Space size="middle" className="float-left">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="button"
                    onClick={SubmitPage}
                    className="float-right Radius8"
                  >
                    {load === false ? "Save" : antIcon}
                  </Button>
                </Space>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default AddPage;
