import React from "react";
import "antd/dist/antd.css";
import { Button, Space, Popconfirm, Spin, notification, message } from "antd";
import { Table } from "ant-table-extensions";
import Sidebar from "../Sidebar";
import { ReactComponent as DeleteIcon } from "../../assests/delete.svg";
import { ReactComponent as ViewIcon } from "../../assests/eyefill.svg";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";
import axios from "axios";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import { Modal, Select } from "antd";
import Footer from "../Footer";
import Header from "../Header";
import { CSVLink } from "react-csv";
const { RangePicker } = DatePicker;
var customDate;

function onChange(value, dateString) {
  customDate = dateString;
}
function onOk(value) {
  const link = "users/byPeriod";
  axios
    .post(link, {
      startDate: this.state.firstDayOfMonth,
      endDate: this.state.today,
    })
    .then((res) => {
      if (res.data.success === true) {
        this.setState({
          data: res.data.Data,
        });
      }
    });
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;
const columns = [
  {
    title: "First Name",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (record) => (
      <>
        <span className="">{record.firstName}</span>
      </>
    ),
  },
  {
    title: "Last Name",
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    render: (record) => (
      <>
        <span className="userLastName">{record.lastName}</span>
      </>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: "Subscription Type",
    sorter: (a, b) =>
      a.packageID?.packageType?.localeCompare(b?.packageID?.packageType),
    render: (record) => (
      <>
        <span className="">{record?.packageID?.packageType}</span>
      </>
    ),
  },
  {
    title: "Subscription Name",
    sorter: (a, b) => a.packageID?.name?.localeCompare(b?.packageID?.name),
    render: (record) => (
      <>
        <span className="">{record?.packageID?.name}</span>
      </>
    ),
  },
  {
    title: "Role",
    dataIndex: "role",
    sorter: (a, b) => a.role.localeCompare(b.role),
  },
  {
    title: "Country",
    dataIndex: "country",
    sorter: (a, b) => a.country.localeCompare(b.country),
  },
  {
    title: "State",
    dataIndex: "state",
    sorter: (a, b) => a.state.localeCompare(b.state),
  },
  {
    title: "Date Added",
    sorter: true,
    render: (record) => (
      <>{dateFormat(record.createdAt, "yyyy/mm/dd, h:MM:ss tt")}</>
    ),
  },
  {
    title: "Action",
    key: "action",
    sorter: false,
    render: (record) => (
      <>
        <Space size="middle">
          {(!record?.packageID ||
            record?.packageID?.packageType === "Free") && (
            <NavLink to={"/user/upgrade-package/" + record._id}>
              {" "}
              <Button className=" bgBlue" size={"small"}>
                {" "}
                Upgrade package{" "}
              </Button>{" "}
            </NavLink>
          )}
          <NavLink to={"/user/edit/" + record._id}>
            {" "}
            <Button className="bgBlue" size={"small"}>
              {" "}
              Edit{" "}
            </Button>{" "}
          </NavLink>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => DeleteUser(record._id)}
          >
            <Button className="" size={"small"} type="primary" danger>
              {" "}
              <DeleteIcon />{" "}
            </Button>{" "}
          </Popconfirm>
          <NavLink to={"/user/detail/" + record._id}>
            {" "}
            <Button className=" bgGreen" size={"small"}>
              {" "}
              <ViewIcon />{" "}
            </Button>
          </NavLink>
        </Space>
      </>
    ),
  },
];

const userHeaders = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Subscription Type", key: "packageType" },
  { label: "Subscription Name", key: "stripePackageType" },
  { label: "Role", key: "role" },
  { label: "Country", key: "country" },
  { label: "State", key: "state" },
  { label: "City", key: "city" },
  { label: "Date Added", key: "createdAt" },
];
const DeleteUser = (id) => {
  const link = "users/delete-user-by-admin";

  axios
    .put(link, {
      userIds: [id],
    })
    .then((res) => {
      message.success("User Deleted Successfully");
      window.location = "/users";
    })
    .catch(function (error) {});
};
var array;
// const expandable = { expandedRowRender: record => <p>{record.description}</p> };
const showHeader = true;
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // DeleteMultiple(selectedRowKeys)
    if (selectedRowKeys?.length > 0) {
      array = selectedRowKeys;
    }
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

const DeleteMultiple = () => {
  const link = "users/delete-user-by-admin";
  axios
    .put(link, {
      userIds: array,
    })
    .then((res) => {
      message.success("User Deleted Successfully");
      window.location = "/users";
    })
    .catch(function (error) {});
};
class Users extends React.Component {
  state = {
    bordered: false,
    loading: false,
    size: "small",
    // expandable,
    title: undefined,
    showHeader,
    rowSelection: {},
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    // bottom: 'bottomRight',
    search: "",
    bulkActions: "",
    filter: "",
    load: false,
    errorMessage: null,
    current: 1,
    totalPage: 0,
    selectedRowKeys: [], // Check here to configure the default column
    selectionType: "checkbox",
    today: moment().format("YYYY-MM-DD"),
    firstDayOfMonth: moment().startOf("month").format("YYYY-MM-DD"),
    firstDayOfYear: moment().startOf("year").format("YYYY-MM-DD"),
    firstDayOfLastMonth: moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD"),
    lastDayOfLastMonth: moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD"),
    firstDayOfLastYear: moment()
      .subtract(1, "years")
      .startOf("year")
      .format("YYYY-MM-DD"),
    lastDayOfLastYear: moment()
      .subtract(1, "years")
      .endOf("year")
      .format("YYYY-MM-DD"),
    day1: moment().subtract(1, "days").format("YYYY-MM-DD"),
    day7: moment().subtract(7, "days").format("YYYY-MM-DD"),
    day30: moment().subtract(30, "days").format("YYYY-MM-DD"),
    start_date: "",
    end_date: "",
    isModalVisible: false,
    navFilter: " ",
    pageSize: 10,
    allUsers: [],
  };
  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    this.setState({ isModalVisible: false });
    const link = "users/byPeriod";
    axios
      .post(link, {
        startDate: customDate[0],
        endDate: customDate[1],
      })
      .then((res) => {
        if (res.data.success === true) {
          this.setState({
            data: res.data.Data,
          });
        }
      });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };
  onHandleChange = (event) => {
    this.setState(
      { ...this.state, current: 1, search: "", navFilter: "" },
      () => {
        const { name, value } = event.target;
        if (name === "filter" && value === "modal") {
          this.setState({ load: true, isModalVisible: true });
        }
        // eslint-disable-next-line
        else if (name === "filter" && value == 1) {
          this.setState({
            load: true,
            start_date: this.state.firstDayOfMonth,
            end_date: this.state.today,
          });
          const link = `users/get-all-users?startDate=${this.state.firstDayOfMonth}&endDate=${this.state.today}`;
          axios.get(link).then((res) => {
            if (res.data.success === true) {
              this.setState({
                data: res.data.data.users,
                totalPage: res.data.data.pages,
                load: true,
              });
            }
          });
        }
        // eslint-disable-next-line
        else if (name === "filter" && value == 7) {
          this.setState({
            load: true,
            start_date: this.state.firstDayOfYear,
            end_date: this.state.today,
          });
          const link = `users/get-all-users?startDate=${this.state.firstDayOfYear}&endDate=${this.state.today}`;
          axios.get(link).then((res) => {
            if (res.data.success === true) {
              this.setState({
                data: res.data.data.users,
                totalPage: res.data.data.pages,
                load: true,
              });
            }
          });
        }
        // eslint-disable-next-line
        else if (name === "filter" && value == 30) {
          this.setState({
            load: true,
            start_date: this.state.firstDayOfLastMonth,
            end_date: this.state.lastDayOfLastMonth,
          });
          const link = `users/get-all-users?startDate=${this.state.firstDayOfLastMonth}&endDate=${this.state.lastDayOfLastMonth}`;
          axios.get(link).then((res) => {
            if (res.data.success === true) {
              this.setState({
                data: res.data.data.users,
                totalPage: res.data.data.pages,
                load: true,
              });
            }
          });
          // eslint-disable-next-line eqeqeq
        } else if (name === "filter" && value == 40) {
          this.setState({
            load: true,
            start_date: this.state.firstDayOfLastYear,
            end_date: this.state.lastDayOfLastYear,
          });
          const link = `users/get-all-users?startDate=${this.state.firstDayOfLastYear}&endDate=${this.state.lastDayOfLastYear}`;
          axios.get(link).then((res) => {
            if (res.data.success === true) {
              this.setState({
                data: res.data.data.users,
                totalPage: res.data.data.pages,
                load: true,
              });
            }
          });
        } else {
          this.setState({
            [name]: value,
            start_date: "",
            end_date: "",
          });
        }
      }
    );
  };

  handlePageSizeChange = (value) => {
    this.setState(
      {
        pageSize: value,
      },
      () => {
        this.fetchData();
      }
    );
  };

  fetchData = async () => {
    try {
      const res = await axios.get(
        `users/get-all-users?page_number=${this.state.current}&filter_value=${this.state.navFilter}&search_keyword=${this.state.search}&start_date=${this.state.start_date}&end_date=${this.state.end_date}&page_size=${this.state.pageSize}`
      );
      this.setState({
        data: res.data.data.users,
        totalPage: res.data.data.pages,
        load: true,
      });
    } catch (error) {
      if (error?.response?.data?.code === 401) {
        this.setState({ errorMessage: "Session Expired! Login Again" });
        localStorage.clear();
        window.location = "/login";
      } else if (error?.response?.data?.code !== 401) {
        // this.setState({ errorMessage: error.response.data.message })
        message.error(error?.response?.data?.message);
      }
    }
  };

  fetchAllUserData = async () => {
    const res = await axios.get(
      `users/get-all-users?filter_value=${this.state.navFilter}&search_keyword=${this.state.search}&start_date=${this.state.start_date}&end_date=${this.state.end_date}`
    );
    const data = res.data.data.users.map((item) => {
      return {
        firstName: item?.firstName,
        lastName: item?.lastName,
        email: item?.email,
        packageType: item?.packageID?.packageType,
        stripePackageType: item?.packageID?.stripe_package_type,
        role: item?.role,
        country: item?.country,
        state: item?.state,
        city:
          item?.region !== "undefined" && item?.region !== undefined
            ? item?.region
            : "",
        createdAt: item?.createdAt,
      };
    });
    this.setState({
      allUsers: data,
    });
  };

  componentDidMount() {
    this.fetchData(1);
    this.fetchAllUserData();
  }

  handleYScrollChange = (enable) => {
    this.setState({ yScroll: enable });
  };

  handleDataChange = (hasData) => {
    this.setState({ hasData });
  };

  onPageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.fetchData();
      }
    );
  };

  render() {
    const { xScroll, yScroll, ...state } = this.state;
    const scroll = {};
    if (yScroll) {
      scroll.y = 240;
    }
    if (xScroll) {
      scroll.x = "100vw";
    }

    const tableColumns = columns.map((item) => ({
      ...item,
      ellipsis: state.ellipsis,
    }));
    if (xScroll === "fixed") {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = "right";
    }

    return (
      <>
        <Sidebar />
        <div className="content pt-3">
          <Header />
          <div className="col-12 pt-3">
            <div className="displayFlex align-items-center mb-3">
              <div
                className="displayFlex align-items-center"
                style={{ gap: "8px" }}
              >
                <h2 className="m-0">Users</h2>
                <NavLink
                  to="/user/add"
                  className="navLinking m-0"
                  // style={{ marginTop: "10px" }}
                >
                  <Button className="bgBlue" size={"small"}>
                    {" "}
                    Add User{" "}
                  </Button>
                </NavLink>
              </div>
              <div className="displayFlex">
                <input
                  type="text"
                  className="lightBlue border-0 outline"
                  value={state.search}
                  onChange={(e) => {
                    this.setState({ ...state, search: e.target.value });
                  }}
                  name="search"
                  placeholder="Search"
                  style={{ height: "fit-content" }}
                />
                <Button
                  className="bgBlue mx-1"
                  size={"small"}
                  onClick={() => {
                    this.setState({ ...state, current: 1 }, () => {
                      this.fetchData();
                    });
                  }}
                >
                  {" "}
                  Search{" "}
                </Button>
              </div>
            </div>
            <div className="row">
              <button
                style={
                  this.state.navFilter === " "
                    ? { padding: "5px 15px", backgroundColor: "#FE6F35" }
                    : {}
                }
                className="btn"
                onClick={() => {
                  this.setState(
                    { ...state, navFilter: " ", current: 1 },
                    () => {
                      this.fetchData();
                    }
                  );
                }}
              >
                All{" "}
              </button>
              <button className="btn">| </button>
              <button
                className="btn"
                style={
                  this.state.navFilter === "is_admin"
                    ? { padding: "5px 15px", backgroundColor: "#FE6F35" }
                    : {}
                }
                onClick={() => {
                  this.setState(
                    { ...state, navFilter: "is_admin", current: 1 },
                    () => {
                      this.fetchData("", "is_admin");
                    }
                  );
                }}
              >
                Admin{" "}
              </button>
              <button className="btn">| </button>
              <button
                className="btn"
                style={
                  this.state.navFilter === "is_editor"
                    ? { padding: "5px 15px", backgroundColor: "#FE6F35" }
                    : {}
                }
                onClick={() => {
                  this.setState(
                    { ...state, navFilter: "is_editor", current: 1 },
                    () => {
                      this.fetchData("", "is_editor");
                    }
                  );
                }}
              >
                Editors{" "}
              </button>
              <button className="btn">| </button>
              <button
                className="btn"
                style={
                  this.state.navFilter === "is_free"
                    ? { padding: "5px 15px", backgroundColor: "#FE6F35" }
                    : {}
                }
                onClick={() => {
                  this.setState(
                    { ...state, navFilter: "is_free", current: 1 },
                    () => {
                      this.fetchData("", "is_free");
                    }
                  );
                }}
              >
                Free Members{" "}
              </button>
              <button className="btn">| </button>
              <button
                className="btn"
                style={
                  this.state.navFilter === "is_monthly"
                    ? { padding: "5px 15px", backgroundColor: "#FE6F35" }
                    : {}
                }
                onClick={() => {
                  this.setState(
                    { ...state, navFilter: "is_monthly", current: 1 },
                    () => {
                      this.fetchData("", "is_monthly");
                    }
                  );
                }}
              >
                Monthly Members{" "}
              </button>
              <button className="btn">| </button>
              <button
                className="btn"
                style={
                  this.state.navFilter === "is_new_members"
                    ? { padding: "5px 15px", backgroundColor: "#FE6F35" }
                    : {}
                }
                onClick={() => {
                  this.setState(
                    { ...state, navFilter: "is_new_members", current: 1 },
                    () => {
                      this.fetchData("", "is_new_members");
                    }
                  );
                }}
              >
                New Members{" "}
              </button>
              <button className="btn">| </button>
              <button
                className="btn"
                style={
                  this.state.navFilter === "is_deactivate"
                    ? { padding: "5px 15px", backgroundColor: "#FE6F35" }
                    : {}
                }
                onClick={() => {
                  this.setState(
                    { ...state, navFilter: "is_deactivate", current: 1 },
                    () => {
                      this.fetchData("", "is_deactivate");
                    }
                  );
                }}
              >
                Cancelled Members{" "}
              </button>
            </div>
            <div className="displayFlex mt-3">
              <div className="displayFlex">
                <select
                  // value={state.bulkActions}
                  onChange={this.onHandleChange}
                  name="bulkActions"
                  className="blue Radius2"
                >
                  <option value="" className="blue">
                    Bulk Actions
                  </option>
                  <option value="delete" className="blue">
                    Delete
                  </option>
                </select>
                <Button
                  className="bgBlue mx-2"
                  size={"small"}
                  onClick={DeleteMultiple}
                >
                  {" "}
                  Apply{" "}
                </Button>
              </div>
              <div className="displayFlex">
                <Button className="border-0 mx-2" size={"small"}>
                  {" "}
                  Filter{" "}
                </Button>
                <select
                  onChange={this.onHandleChange}
                  name="filter"
                  className="blue Radius2"
                >
                  <option value="" className="blue">
                    Select Period
                  </option>
                  <option value="1" className="blue">
                    {" "}
                    Month to Date
                  </option>
                  <option value="7" className="blue">
                    Year to Date
                  </option>
                  <option value="30" className="blue">
                    Last Month
                  </option>
                  <option value="40" className="blue">
                    Last Year
                  </option>
                  <option className="blue" value="modal">
                    Custom{" "}
                  </option>
                </select>
                <Modal
                  title="Custom Filter"
                  visible={this.state.isModalVisible}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                >
                  <RangePicker
                    // showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD"
                    onChange={onChange}
                    onOk={onOk}
                  />
                </Modal>
              </div>
            </div>
            <CSVLink
              filename="users.csv"
              data={this.state.allUsers}
              headers={userHeaders}
            >
              <div
                style={{
                  backgroundColor: "#FE6F35",
                  width: "fit-content",
                  color: "white",
                  padding: "5px 15px",
                  marginTop: "10px",
                }}
              >
                Export to CSV
              </div>
            </CSVLink>
          </div>
          <div
            className="mt-3"
            style={{ marginLeft: "15px", marginRight: "15px" }}
          >
            <Table
              {...this.state}
              pagination={{
                pageSize: this.state.pageSize,
                current: this.state.current,
                onChange: this.onPageChange,
                total: this.state.totalPage * this.state.pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current, size) => {
                  this.handlePageSizeChange(size);
                },
                pageSizeOptions: [10, 25, 50, 100],
              }}
              columns={tableColumns}
              dataSource={state.hasData ? this.state.data : null}
              rowKey="_id"
              rowSelection={{
                type: state.selectionType,
                ...rowSelection,
              }}
              loading={{
                indicator: (
                  <div>
                    <Spin indicator={antIcon} />
                  </div>
                ),
                spinning: !this.state.load,
              }}
              scroll={scroll}
              className="table-responsive"
            />
          </div>
        </div>
        <Footer />
        {this.state.errorMessage !== null &&
          notification["error"]({
            message: "Error",
            description: this.state.errorMessage,
            placement: "bottomLeft",
          })}
      </>
    );
  }
}
export default Users;
