import Sidebar from "../Sidebar";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import avatar from "../../assests/avatar.png";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import Footer from "../Footer";
import Header from "../Header";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const AddUser = () => {
  const initialstate = {
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    // username: "",
    // usernameError: "",
    email: "",
    emailError: "",
    phone: "",
    phoneError: "",
    date: "",
    dateError: "",
    state: "",
    stateError: "",
    image: "",
    packageStatus: "",
    packageId: "",
    country: "",
    countryError: "",
    password: "",
    passwordError: "",
    role: "",
    roleError: "",
  };
  const [packages, setpackages] = useState();
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [DetailData, setDetailData] = useState(initialstate);
  // eslint-disable-next-line
  const {
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    // username,
    // usernameError,
    email,
    emailError,
    // eslint-disable-next-line no-unused-vars
    image,
    date,
    dateError,
    phoneError,
    state,
    stateError,
    country,
    countryError,
    password,
    passwordError,
    role,
    roleError,
    // eslint-disable-next-line no-unused-vars
    packageId,
    // eslint-disable-next-line no-unused-vars
    packageStatus,
    phone,
  } = DetailData;

  const [loading, setLoading] = useState(false);
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setDetailData({
      ...DetailData,
      [name]: value,
    });
  };
  const fetchCategory = async () => {
    try {
      const res = await Promise.all([axios.get("package")]);
      setpackages(res[0].data.packages);
      // setDetailData({
      //     ...DetailData,
      //     packages: res[0].data.packages
      // })
    } catch {
      // throw Error("Promise");
      message.error("Network Problem");
    }
  };
  useEffect(() => {
    fetchCategory();
    // eslint-disable-next-line
  }, []);

  const SubmitUser = () => {
    if (validate()) {
      setLoading(true);
      var axios = require("axios");
      var FormData = require("form-data");
      var data = new FormData();
      data.append("firstName", firstName);
      data.append("lastName", lastName);
      data.append("email", email);
      data.append("password", password);
      // data.append("username", username);
      data.append("phone", "phone");
      data.append("packageID", selectedPackage);
      data.append("packageStatus", true);
      data.append("country", country);
      data.append("state", state);
      data.append("dob", date);
      data.append("image", selectedFile === undefined ? image : selectedFile);

      var config = {
        method: "post",
        url: `users`,
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response) {
            setLoading(false);
            message.success("User Added Successfully");
            window.location = "/user";
          }
        })
        .catch(function (error) {
          setLoading(false);
          if (error?.response) {
            message.error(error?.response?.data?.message);
          } else {
            message.error("Netork Problem");
          }
        });
    }
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  function validate() {
    let firstNameError = "";
    let lastNameError = "";
    // let usernameError = "";
    let emailError = "";
    let dateError = "";
    let countryError = "";
    let stateError = "";
    let passwordError = "";
    let phoneError = "";
    let roleError = "";
    if (!firstName) {
      firstNameError = "First Name is required";
    }
    if (!lastName) {
      lastNameError = "Last Name is required";
    }
    // if (!username) {
    //   usernameError = "Username is required";
    // }
    if (!date) {
      dateError = "DOB is required";
    }
    if (!country) {
      countryError = "Country is required";
    }
    if (!state) {
      stateError = "State is required";
    }
    if (!phone) {
      phoneError = "Phone No is required";
    }
    if (!role) {
      roleError = "Role is required";
    }
    // eslint-disable-next-line
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email || reg.test(email) === false) {
      emailError = "Invalid Email";
    }
    if (!password) {
      passwordError = "Password is required";
    }
    if (
      passwordError ||
      emailError ||
      firstNameError ||
      lastNameError ||
      // usernameError ||
      emailError ||
      dateError ||
      countryError ||
      stateError ||
      passwordError ||
      phoneError ||
      roleError
    ) {
      setDetailData({
        ...DetailData,
        firstNameError,
        lastNameError,
        // usernameError,
        emailError,
        dateError,
        countryError,
        stateError,
        passwordError,
        phoneError,
        roleError,
      });
      return false;
    }
    // else {
    //     setDetailData({
    //         ...DetailData,
    //         firstName: "",
    //         lastName: "",
    //         username: "",
    //         email: "",
    //         date: "",
    //         country: "",
    //         state: "",
    //         password: "",
    //         phone: "",
    //         role: ""
    //     })
    // }
    return true;
  }
  return (
    <>
      <Sidebar />
      <div className="content pt-3 pl2">
        <Header />
        <h2 className="ml-2 pt-3">Add User</h2>
        <div className="displayFlex mt-4 ml-2">
          <div className="displayFlex">
            {/* <UploadFile /> */}
            <div>
              {selectedFile === undefined ? (
                <img src={avatar} alt="imagee" className="avatar mr-3" />
              ) : (
                <img src={preview} alt="" className="avatar mx-3" />
              )}
              <label
                htmlFor="files"
                className="btn"
                style={{
                  backgroundColor: "#F2F8FF",
                  border: "1px solid #fe6f35",
                  color: "black",
                }}
              >
                Upload Photo
              </label>
              <input
                type="file"
                id="files"
                onChange={onSelectFile}
                style={{ color: "white", visibility: "hidden" }}
              />
            </div>
          </div>
        </div>
        <div className=" mt-4">
          <div className="row col-12 ml-2 px-0">
            <div className="row col-lg-8 col-12">
              <input
                placeholder="First Name"
                name="firstName"
                value={firstName}
                onChange={onHandleChange}
                className="col-md-5 col-12 fillColor px-2 my-1 py-2"
              />

              <input
                placeholder="Last Name"
                name="lastName"
                value={lastName}
                onChange={onHandleChange}
                className="col-md-5 col-12 ml-2 fillColor px-2 my-1 py-2"
              />

              {/* </div> */}
              {/* <div className='row '> */}
              <span className="text-danger col-md-5 col-12 mt-2 px-2 py-2">
                {firstNameError}
              </span>
              <span className="text-danger col-md-5 col-12 mt-2 px-2 py-2">
                {lastNameError}
              </span>
              {/* </div>
                            <div className='row'> */}
              {/* <input
                placeholder="Username"
                name="username"
                value={username}
                onChange={onHandleChange}
                className="col-md-5 col-12 fillColor px-2 my-1 py-2"
              /> */}
              <input
                placeholder="Email"
                name="email"
                value={email}
                onChange={onHandleChange}
                className="col-md-5 col-12 ml-2 fillColor px-2 my-1 py-2"
              />
              <input
                type="date"
                onChange={onHandleChange}
                name="date"
                value={date}
                className="col-md-5 col-12 ml-2 fillColor px-2 my-1 py-2"
              />
              {/* </div>

                            <div className='row '> */}
              {/* <span className="text-danger col-md-5 col-12 mt-2 px-2 py-2">
                {usernameError}
              </span> */}
              <span className="text-danger col-md-5 col-12 mt-2 px-2 py-2">
                {emailError}
              </span>
              <span className="text-danger col-md-5 col-12 mt-2 px-2 py-2">
                {dateError}
              </span>
              {/* </div>
                            <div className='row'> */}

              <input
                placeholder="State"
                name="state"
                value={state}
                onChange={onHandleChange}
                className="col-md-5 col-12 fillColor px-2 my-1 py-2"
              />
              <input
                placeholder="Country"
                name="country"
                value={country}
                onChange={onHandleChange}
                className="col-md-5 col-12 ml-2 fillColor px-2 my-1 py-2"
              />
              {/* </div>
                            <div className='row '> */}
              <span className="text-danger col-md-5 col-12 mt-2 px-2 py-2">
                {stateError}
              </span>
              <span className="text-danger col-md-5 col-12 mt-2 px-2 py-2">
                {countryError}
              </span>
              {/* </div>
                            <div className='row'> */}

              <input
                type="password"
                name="password"
                value={password}
                onChange={onHandleChange}
                placeholder="Password"
                className="col-md-5 col-12 fillColor px-2 my-1 py-2"
              />
              <input
                placeholder="Phone"
                name="phone"
                type="number"
                value={phone}
                onChange={onHandleChange}
                className="col-md-5 col-12 ml-2 fillColor px-2 my-1 py-2"
              />
              {/* </div>

                            <div className='row '> */}

              <span className="text-danger col-md-5 col-12 mt-2 px-2 py-2">
                {passwordError}
              </span>
              <span className="text-danger col-md-5 col-12 mt-2 px-2 py-2">
                {phoneError}
              </span>
              {/* </div>
                            <div className="row"> */}

              <select
                onChange={onHandleChange}
                name="role"
                className="col-md-5 col-12 fillColor px-2 my-1 py-2"
              >
                <option value={role ? role : ""} className="blue">
                  {role ? role : "Role"}
                </option>
                <option value="user" className="blue">
                  User
                </option>
                <option value="editor" className="blue">
                  Editor
                </option>
                <option value="admin" className="blue">
                  Admin
                </option>
              </select>
              <select
                onChange={(e) => setSelectedPackage(e.target.value)}
                name="selectedPackage"
                className="col-md-5 col-12 ml-2 fillColor px-2 my-1 py-2"
              >
                <option value="" className="blue">
                  Select Package
                </option>
                {packages?.map((item) => {
                  return (
                    <option value={item?._id} className="blue">
                      {item?.name}
                    </option>
                  );
                })}
                {/* <option value="editor" className='blue'>Editor</option>
                                    <option value="admin" className='blue'>Admin</option> */}
              </select>
              {/* </div>

                            <div className='row '> */}

              <span className="text-danger col-md-5 col-12 mt-2 px-2 py-2">
                {roleError}
              </span>
            </div>
          </div>
          <button
            onClick={SubmitUser}
            className="btn float-left Radius8 White py-2 my-1 px-3 mt-2 ml-2"
          >
            {loading === true ? antIcon : "Submit"}
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default AddUser;
